
import React, { createContext, useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import {
  Grid,
  Container,
  CircularProgress,
  Box,
  Button,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { AuthService } from 'src/services/AuthService';

import { SelectedShop } from 'src/contexts/SelectedShop';
import InvoiceDetails from '../../Users/shopProfile/InvoiceDetails';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type ViewSingleClientProfileProps = {
  callBackPage: () => void;
  shopId: string;
};
const AdminInvoice: React.FC<ViewSingleClientProfileProps> = ({ callBackPage, shopId }) => {

  const [searchParams] = useSearchParams();
  const { isButtonClicked, setButtonClicked } = useContext(SelectedShop);
  const [userDetails, setUser] = useState<any>([]);
  const [shopDetails, setShop] = useState<any>([]);
  
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
 

  useEffect(() => {
    
    getShopByShopId(shopId);
    setUserId(shopDetails.createdBy);
    getUserByUserId(userId)
    
    
  }, [userId, isButtonClicked, searchParams, shopId]);

  const getUserByUserId = (userId: string) => {
    AuthService.getUserByUserId(userId).then((res) => {
      if (res) {
        setUser(res.data);
      } 
    });
  };

  const getShopByShopId = (shopId: string) => {
    AuthService.getShopByShopId(shopId).then((res) => {
      if (res) {
        setShop(res.data);
      } 
    });
  };

  const back = () => {
    callBackPage()
  };

  return (
    <>
      {/* <Container sx={{ mt: 3, position: 'relative' }} maxWidth="xl"> */}
        
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12} md={12}>
            <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => back()}
                  sx={{
                    borderColor: 'primary.main',
                    color: 'primary.main', 
                    '&:hover': {
                      borderColor: 'primary.dark', 
                      color: 'primary.dark'     
                    },
                    mb: 3
                  }}
                >
                  Back
              </Button>
               <InvoiceDetails
                shopId={shopId}
                currency={userDetails?.currency?.currencyName}
              />
            </Grid>
          </Grid>
        
      {/* </Container> */}
     
    </>
  );
}

export default AdminInvoice;



import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  styled
} from '@mui/material';
import {  useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { ManagementServices } from 'src/services/ManagementServices';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function ShopMessage({
 open,
            onClose,
            allShops,
            setAllShops
}) {
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    ShopMessage: yup.string().typeError('Not a String').required('Required')
  });

  const formikupdate = useFormik({
    initialValues: {
      ShopMessage:  ''
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateShop(values);
      formikupdate.resetForm();
    }
  });

  const updateShop = (values) => {
    onClose();
    const data = {
      shopMessage: values.ShopMessage,
        numbers: allShops.filter(shop => shop?.activeStatusMessage == true)
            .map(shop => '94' + (shop?.phoneNumber?.slice(1) || ''))
      };
        Swal.fire({
          title: 'Are you sure?',
          text: "Do you want to send this message!",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.isConfirmed) {
           ManagementServices.sendMessagesByAdmin(data).then((res) => {
             if (res.success) {
               const updatedShops = allShops.map((shop) => ({
                 ...shop,
                 activeStatusMessage: false
               }));
               setAllShops(updatedShops);
               Swal.fire('Message Sent', 'Successfully', 'success');
             } else {
               Swal.fire({
                 icon: 'error',
                 title: res.error,
                 confirmButtonColor: '#FD7F00'
               });
             }
           });
          } else {
           const updatedShops = allShops.map((shop) => ({
             ...shop,
             activeStatusMessage: false
           }));
           setAllShops(updatedShops);
          }
        });
    
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
        Shop Message
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <DialogContent dividers>
            <form onSubmit={formikupdate.handleSubmit}>
             {/* <Typography>Shop Message</Typography> */}
              <TextField
                required
                id="ShopMessage"
                multiline
                rows={4}
                name="ShopMessage"
                value={formikupdate.values.ShopMessage}
                onChange={formikupdate.handleChange}
                fullWidth
              />
              <div style={{ height: 20 }}></div>

              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Send Message
              </Button>
            </form>
        </DialogContent>
      )}
    </BootstrapDialog>
  );
}

export default ShopMessage;

import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Box,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Typography,
  Tooltip,
  IconButton,
  TablePagination,
  useTheme,
  Avatar,
  styled,
  Button,
  TextField,
  InputAdornment,
  Autocomplete,
  Grid,
  CircularProgress,
  Container,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import numeral from 'numeral';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CopyAllTwoTone from '@mui/icons-material/CopyAllTwoTone';
import UserContext from 'src/context/UserContext';
import { UserDetails, VerifyStatus } from 'src/models/User';
import { ManagementServices } from 'src/services/ManagementServices';
import Swal from 'sweetalert2';
import { activeStatus } from 'src/models/ChapterGroup';
import { DeleteTwoTone } from '@mui/icons-material';
import { CategoryDetails } from 'src/models/Category';

import { IconDetails } from 'src/models/Icon';
import { SketchPicker } from 'react-color';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./../../ToastStyle.css";
import moment from 'moment';

import SearchIcon from '@mui/icons-material/Search';
import { InventoryService } from 'src/services/InventoryService';
import { ShopsDetails } from 'src/models/Shops';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PreviewIcon from '@mui/icons-material/Preview';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import Papa from 'papaparse';
import { TemplateInventoryDetails } from 'src/models/InventoryDetails';
import { TemplateCategoryDetails } from 'src/models/TemplateCategory';
import { AppResponse } from 'src/models/Response';
import { Link, useSearchParams } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { WithMaterialUI } from '../create-category-popup';
import { SelectedShop } from 'src/contexts/SelectedShop';
import AddProduct from 'src/components/AddProduct';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import CreateCustomer from '../customers/CreateCustomer';
import CustomerEdit from '../customers/CustomerEdit';


export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Filters {
  search: string;
  status?: any;
}

const applyFilters = (TemplateCategories: ShopsDetails[], filters: Filters): ShopsDetails[] => {
  return TemplateCategories.filter((category) => {
    let matches = true;

    if (filters.search) {
      const keyword = filters.search.toLowerCase();
      if (!category.shopName?.toLowerCase().includes(keyword)) {
        matches = false;
      }
    }
    return matches;
  });
};

const applyPagination = (
  allGroups: CategoryDetails[],
  page: number,
  limit: number
): any[] => {
  return allGroups.slice(page * limit, page * limit + limit);
};

interface Props {
  AllCategories: CategoryDetails[];
  setAllCategories: React.Dispatch<
    React.SetStateAction<CategoryDetails[]>
  >;
}
type ViewSingleClientProfileProps = {
  callBackPage: () => void;
  shopId: string;
};
const AdminCustomer: React.FC<ViewSingleClientProfileProps> = ({ callBackPage, shopId }) => {

  const [AllIcons, setAllIcons] = useState([] as IconDetails[]);
  const [selectedAllGroups, setSelectedAllGroups] = useState<string[]>([]);
  const selectedBulkActions = selectedAllGroups.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [filters, setFilters] = useState<Filters>({ search: null });
  const LIMIT_P_C = 10;
  const LIMIT_A_C = 10;
  const [allShops, setAllShops] = useState<ShopsDetails[]>([]);
  const [totalShops, setTotalShops] = useState(0);
  const [isOffset_p_c, setOffset_p_c] = useState<number>(0);
  // const [seeMore_p_c, setSeeMore_p_c] = useState(false);
  const [isOffset_a_c, setOffset_a_c] = useState<number>(0);
  const [seeMore_a_c, setSeeMore_a_c] = useState(false);
  const [user] = useContext(UserContext);
  // const [shopId, setId] = useState();
  const [mainTableInventory, setMainTableInventory] = useState<TemplateInventoryDetails[]>([]);
  const filteredMainTableInventory = applyFilters(mainTableInventory, filters);
  const paginatedMainTableInventory = applyPagination(filteredMainTableInventory, page, limit);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCSV, setOpenCSV] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [selectedShopId, setSelectedShopId] = useState();
  const [selectedShopUserId, setSelectedShopUserId] = useState();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [AllCategories, setAllCategories] = useState(
    [] as CategoryDetails[]
  );
  const [customerList, setCustomerList] = useState<any[]>([]);
   const { isButtonClicked, setButtonClicked } = useContext(SelectedShop);
   const [totalCustomers, setTotalCustomers] = useState(0);
   const [openAddProduct, setOpenAddProduct] = useState(false);
  //  const [openEdit, setOpenEdit] = useState(false);
   const [openEditCustomer, setOpenEditCustomer] = useState(false);
   const [selectedCustomerId, setSelectedCustomerId] = useState();
   const [loading, setLoading] = useState<boolean>(true);
   const [searchParams] = useSearchParams();
   const [isLoading, setIsLoading] = useState(false);
   const [ifSearchChanged, setIfSearchChanged] = useState(false);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  useEffect(() => {
    getAllCustomers(shopId);
  }, [page, limit, currentSearchTerm, isButtonClicked, searchParams]);



  const getAllCustomers = async (shopId) => {
    try {
      // setLoading(false);
      const params = new URLSearchParams({
        search: currentSearchTerm,
        page: (page + 1).toString(),  // Convert back to 1-based for the API
        limit: limit.toString(),
      });
      const res = await ManagementServices.getAllCustomers(shopId, params.toString());
      if (res.success) {
        if(ifSearchChanged ){
          const customersData = res.data.customers || [];
          setCustomerList(customersData);
        }
        else{
          const customersData = res.data.customers || [];
          setCustomerList(prevList => [...prevList, ...customersData]);
        }
       // setCustomerList(res.data.customers)
        setIsLoading(false)
        setIfSearchChanged(false)
        setTotalCustomers(res.data.total);    
       
      } else {
        // setSeeMore_p_c(false);
        toast.error('Failed to fetch Customers');
      }
    } catch (error) {
      console.error("Error fetching Customers:", error);
      toast.error('An error occurred while fetching Customers');
    }
    finally {
          setLoading(false);
    }
  }




  const handlePageChange = (event: any, newPage: number): void => {
    setIsLoading(true)
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
    setPage(0);

  };

  const filteredAllGroups = applyFilters(allShops, filters);
  const paginatedAllGroups = applyPagination(filteredAllGroups, page, limit);
  const selectedSomeCryptoOrders =
    selectedAllGroups.length > 0 &&
    selectedAllGroups.length < AllCategories.length;
  const selectedAllCryptoOrders =
    selectedAllGroups.length === AllCategories.length;
  const theme = useTheme();




  const handleSearch = () => {
    // setPage(0);
    // getAllCustomers(searchParams.get('shopId'));
    setCurrentSearchTerm(searchKeyword);
    setIfSearchChanged(true)
    setPage(0);
  };

  const handleSearchClear = () => {
    setIfSearchChanged(true)
    setSearchKeyword('');
    setCurrentSearchTerm('');
    setPage(0);
    // setSearchKeyword('');
    // setPage(0);
    // getAllCustomers(searchParams.get('shopId'));
  };

  const openCloseAddProduct = () => {
    getAllCustomers(searchParams.get('shopId'));
    setOpenAddProduct(!openAddProduct);
   
  }

  const openCloseEditCustomer = (customerId : any,index:any) => {
    getAllCustomers(searchParams.get('shopId'));
    setOpenEditCustomer(!openEditCustomer);
    setEditIndex(index)
    setSelectedCustomerId(customerId);
  }

 


  const deleteConform = (customerId: any,index :any) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to remove this customer!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Close'
    }).then((result) => {
      if (result.isConfirmed) {
        InventoryService.deleteCustomer(customerId).then((res) => {
          if (res.success) {
            const updatedCustomerList = [...customerList];
            updatedCustomerList.splice(index, 1);
            setCustomerList(updatedCustomerList);
            Swal.fire('Customer Removed', 'Successfully', 'success');
          }
        }).catch((error) => {
          Swal.fire(
            'Error!',
            'There was an error deleting your file.',
            'error'
          );
        });
      }
    });
  };

  const back = () => {
    callBackPage()
  };



  return (
    <>
      <Helmet>
        <title>Customer Details</title>
      </Helmet>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} md={12}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => back()}
            sx={{
              borderColor: 'primary.main',
              color: 'primary.main',
              '&:hover': {
                borderColor: 'primary.dark',
                color: 'primary.dark'
              },
              mb: 3
            }}
          >
            Back
          </Button>
          <Card>
            <CardHeader
              title={
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{
                    fontSize: '2rem',
                    fontWeight: 'bold'
                  }}
                >
                  Customers
                </Typography>
              }
              action={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 2 // Adds space between the components
                  }}
                >
                  {/* <WithMaterialUI
                AllCategories={AllCategories}
                setAllCategories={setAllCategories}
              /> */}
                  <Button variant="outlined" onClick={openCloseAddProduct}>
                    Create New Customer
                  </Button>
                  <TextField
                    variant="outlined"
                    placeholder="Search Product"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handleSearch()}
                            color="primary"
                          >
                            <SearchIcon />
                          </IconButton>
                          <IconButton onClick={handleSearchClear} color="error">
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              }
            />

            <Divider />
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="300px"
              >
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell width={5}>No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>hasCompleted</TableCell>
                      <TableCell>Contact Number</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerList.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography
                            variant="h4"
                            component="h2"
                            sx={{
                              fontSize: '1rem',
                              fontWeight: 'bold'
                            }}
                          >
                            No data to display
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      customerList.map((customer, index) => {
                        const rowIndex = page * limit + index + 1;
                        return (
                          <TableRow hover key={index}>
                            <TableCell>{rowIndex}</TableCell>
                            <TableCell>
                              {customer.customerName
                                ? customer.customerName
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {customer.email ? customer.email : '-'}
                            </TableCell>
                            <TableCell>
                              {customer.hasCompleted
                                ? customer.hasCompleted
                                : '-'}
                            </TableCell>
                            <TableCell>
                              {customer.phoneNumber
                                ? customer.phoneNumber
                                : '-'}
                            </TableCell>

                            <TableCell align="center">
                              <Tooltip title="Edit Customer details" arrow>
                                <IconButton
                                  onClick={() =>
                                    openCloseEditCustomer(customer._id,index)
                                  }
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: theme.palette.primary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                >
                                  <EditTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Delete Customer" arrow>
                                <IconButton
                                  onClick={() => deleteConform(customer._id,index)}
                                  sx={{
                                    '&:hover': {
                                      background: theme.colors.primary.lighter
                                    },
                                    color: theme.palette.primary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                >
                                  <DeleteTwoToneIcon
                                    fontSize="small"
                                    color="error"
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Box p={2} display="flex" justifyContent="center">
              {totalCustomers > customerList.length && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handlePageChange(e, page + 1)}
                >
                  {isLoading ? 'Loading...' : 'See More'}
                </Button>
              )}
              {/* <TablePagination
            component="div"
            // count={filteredAllGroups.length}
            count={totalCustomers}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[20, 40, 60, 80]}
          /> */}
            </Box>
          </Card>
        </Grid>
      </Grid>

      <CreateCustomer
        open={openAddProduct}
        onClose={openCloseAddProduct}
        shopId={searchParams.get('shopId')}
        setCustomerList={setCustomerList}
        customerList={customerList}
      />
      <CustomerEdit
        open={openEditCustomer}
        onClose={openCloseEditCustomer}
        customerId={selectedCustomerId}
        setCustomerList={setCustomerList}
        customerList={customerList}
        index={editIndex}
      />
    </>
  );
};

export default AdminCustomer;

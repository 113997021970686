import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { TemplateCategoryDetails } from 'src/models/TemplateCategory';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SketchPicker } from 'react-color';
import { InventoryService } from 'src/services/InventoryService';
import Swal from 'sweetalert2';
import { useState } from 'react';
import FormData from 'form-data';
import { ArrowDropDown } from '@mui/icons-material';
import Papa from 'papaparse';


function ImportTemplateCSV({ open, onClose, selectedtemplateId }) {

  const [csvFile, setCsvFile] = useState(null); //Import Csv

  const handleUploadCsv = () => {
    if (!csvFile) {
      // Handle the case when no file is selected
      return;
    }

    Papa.parse(csvFile, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: (results) => {
        const inventories = results.data.map((row) => ({
          inventoryTemplateId: selectedtemplateId,
          sKUNumber: row['SKU Number'],
          productName: row['Product Name'],
          price: parseFloat(row['Price']),
          quantity: parseInt(row['Quantity']),
          brandName: row['Brand Name'],
          categoryName: row['Category Name'],
        }));

        // Create new inventories using the InventoryService
        InventoryService.createMultipleInventories(inventories)
          .then((res) => {
            if (res.success) {
              // getDefaultInventory(); 
              setCsvFile(null); // Reset the file input field
              Swal.fire('Inventories Created', 'Successfully', 'success');
            } else {
              Swal.fire({
                icon: 'error',
                title: res.error,
                confirmButtonColor: '#FD7F00',
              });
            }
          })
          .catch((err) => {
            console.error('Error creating inventories:', err);
            Swal.fire({
              icon: 'error',
              title: 'An error occurred while creating inventories',
              confirmButtonColor: '#FD7F00',
            });
          });
      },
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
  };




  return (
    <div>
      <Dialog open={open}
        onClose={onClose} fullWidth
        maxWidth="md">
        <DialogTitle>
          <Typography variant="h4" fontWeight="bold">
            Import CSV
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form>
            <input
              id="csv-file-input"
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <label htmlFor="csv-file-input">
              <Button variant="outlined" color="primary" component="span" fullWidth>
                {csvFile ? csvFile.name : 'Choose CSV File'}
              </Button>
            </label>
            <Box mt={3}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={handleUploadCsv}
                fullWidth
              >
                Create Inventory
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImportTemplateCSV;

import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { useState } from 'react';
import { TemplateCategoryDetails } from 'src/models/TemplateCategory';
import Footer from 'src/components/Footer';
import { Container, Grid } from '@mui/material';
import InventoryTable from './InventoryTable';

const MainInventory = () => {
  const [TemplateCategories, setTemplateCategories] = useState(
    [] as TemplateCategoryDetails[]
  );

  return (
    <>
      <Helmet>
        <title>Main Inventory</title>
      </Helmet>

      {/* <PageTitleWrapper>
        <>
          <PageHeader
            TemplateCategories={TemplateCategories}
            setTemplateCategories={setTemplateCategories}
          />
        </>
      </PageTitleWrapper> */}
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
        >
          <Grid item xs={12} style={{paddingTop: "25px"}}>
            <InventoryTable
              
            />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default MainInventory;

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/services/InventoryService';
import { AuthService } from 'src/services/AuthService';
import { ManagementServices } from 'src/services/ManagementServices';
import { CurrencyDetails } from 'src/models/Currency';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function EditShopNote({
    open,
    selectedShopNote,
  onClose,
  shopId,
  userId,
  allShops,
  setAllShops,
  index
}) {
  const [shopDetails, setShop] = useState<any>([]);
  const [loading, setLoading] = useState(false);



  const validationSchema = yup.object({
    ShopNote: yup.string().typeError('Not a String').required('Required')
  });

  const formikupdate = useFormik({
    initialValues: {
      ShopNote: selectedShopNote || ''
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateShop(values);
      formikupdate.resetForm();
    }
  });

  const updateShop = (values) => {
    onClose();
    const id = shopId;
    const data = {
      shopNote: values.ShopNote
    };
    InventoryService.updateShop(data, id).then((res) => {
      if (res.success) {
        const updatedShopNote = data.shopNote;
        const updatedTemplageProduct = [...allShops];
        updatedTemplageProduct[index] = {
          ...updatedTemplageProduct[index],
          shopNote: updatedShopNote
        };
        setAllShops(updatedTemplageProduct);
        Swal.fire('Shop Updated', 'Successfully', 'success');
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00'
        });
      }
    });
  };

 

  const handleClose = () => {
    onClose();
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
        Edit Shop Note
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <DialogContent dividers>
          {shopDetails ? (
            <form onSubmit={formikupdate.handleSubmit}>
            
              <Typography>Shop Note</Typography>
              <TextField
                required
                id="ShopNote"
                name="ShopNote"
                value={formikupdate.values.ShopNote}
                onChange={formikupdate.handleChange}
                fullWidth
              />
              <div style={{ height: 20 }}></div>
            
          
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Save
              </Button>
            </form>
          ) : (
            <Typography variant="body1">Loading...</Typography>
          )}
        </DialogContent>
      )}
    </BootstrapDialog>
  );
}

export default EditShopNote;

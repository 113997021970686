import { useEffect, useRef, useState, useContext } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import ChangePassword from 'src/components/changePassword';
import UserContext from 'src/context/UserContext';
import { ManagementServices } from 'src/services/ManagementServices';
enum IsFirstTimeUser {
  YES = 'YES',
  NO = 'NO'
}
const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const [openChangePassword, setOpenChangePassword] = useState<boolean>(false)
  const [user, setUser] = useContext(UserContext);
  const [isOpen, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (user?.role == "INVENTORY_ADMIN" && user?.isFirstTime == IsFirstTimeUser.YES) {
      handleChangePaswordModal();
      updateStatus(user?._id,IsFirstTimeUser.NO);
     }
    
  }, [user])

  const updateStatus = (userId: any, isFirstTime)=> {
      const data = {
        id: userId,
        isFirstTime: isFirstTime
      };
      ManagementServices.updateInventoryUser(data)
  }

  const ref = useRef<any>(null);
  

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/sign-in';
  };

  const handleChangePaswordModal = () => {
    setOpenChangePassword(!openChangePassword)
    handleClose()
  }
  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user?.name} src={''} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user?.role}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user?.name} src={''} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user?.role}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          {/* <ListItem button to="/management/profile/details" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary="Profile" />
          </ListItem> */}
          <Button  onClick={handleChangePaswordModal}  >
            <LockTwoToneIcon fontSize="small" />
            <ListItemText primary="Change password" />
          </Button>
          {/* <ListItem
            button
            to="/management/profile/settings"
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Account Settings" />
          </ListItem> */}
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" onClick={logout} fullWidth>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>

      <ChangePassword open={openChangePassword} onClose={handleChangePaswordModal } />
    </>
  );
}

export default HeaderUserbox;

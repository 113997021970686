import { Autocomplete, Box, Button, Container, Dialog, DialogContent, DialogTitle, IconButton, Link, TextField, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/services/InventoryService';



export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function TemplateEdit({ open, onClose, templateId, templateProduct, setTemplateProduct, index }) {
  // const [categoryDetails, setCategory] = useState<any>([]);
  const [templlateDetails, setTemplate] = useState<any>({
    categoryName: '',
  });
 

  useEffect(() => {
    let isMounted = true;
    const getTemplateById = async (templateId: string) => {
      try {
        const res = await InventoryService.getTemplateById(templateId); // Updated service method
        if (isMounted && res) {
          setTemplate(res.data);
        }
      } catch (error) {
        console.log('Failed to fetch template details:', error);
      }
    };
    if (templateId) {
      getTemplateById(templateId);
    }
    return () => {
      isMounted = false;
    };
  }, [templateId]);

 
  const validationSchema = yup.object({
    templateName: yup.string().typeError('Not a String').required('Required'),
  });

  const formikupdate = useFormik({
    initialValues: {
      templateName: templlateDetails?.templateName || '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateCategory(values);
      // formikupdate.resetForm();
    },
  });

  const updateCategory  = (values : any) => {
    onClose();
    const data = {
      templateName: values.templateName,
    };
    InventoryService.updateTemplate(data, templateId).then((res) => {
      if (res.success) {
     const updatedTemplageProduct = [...templateProduct]; 
     updatedTemplageProduct[index] = { ...updatedTemplageProduct[index], templateName: values.templateName };

     setTemplateProduct(updatedTemplageProduct);
        Swal.fire('Template Updated', 'Successfully', 'success');
       // get();
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00',
        });
      }
    });
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      

      {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        <Typography variant="h4" fontWeight="bold">
          Edit Template Details
        </Typography>
      </BootstrapDialogTitle> */}
      
      
      <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
          Edit Template Details
        </Typography>
        
        <DialogContent dividers>
        {templlateDetails ? (
          
          <form onSubmit={formikupdate.handleSubmit}>
            <Typography>
                Template Name
            </Typography>
            <TextField
              required
              id="templateName"
              name="templateName"
              value={formikupdate.values.templateName}
              onChange={formikupdate.handleChange}
              fullWidth
            />

            <div style={{ height: 20 }}></div>

            <Button color="primary" variant="contained" type="submit" fullWidth>
              Update
            </Button>
          </form>
        ) : (
          <Typography variant="body1">Loading...</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default TemplateEdit;

import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { ManagementServices } from 'src/services/ManagementServices';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { CategoryDetails } from 'src/models/Category';
import { useEffect, useState } from 'react';
import { ChapterGroupDetails } from 'src/models/ChapterGroup';
import { IconDetails } from 'src/models/Icon';
import { InventoryService } from 'src/services/InventoryService';
import { VisibilityOff, Visibility } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiDialog-paper': {
    width: '60%', 
    maxWidth: '60%',
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const validationSchema = yup.object({
  userName: yup.string().required('User Name is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  contactNumber: yup.string()
  .test('is-numeric', 'Contact number must contain only digits', (value) => {
    return /^\d*$/.test(value);
  })
  .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
  .required('Contact Number is required'),
  password: yup.string().required('Password is required'),
  role: yup.string().required('Role is required'),
  verifiedStatus: yup.string().required('Verified Status is required'),
  currency: yup.string().required('Currency is required')
});

interface Props {
  AllCategories: CategoryDetails[];
  setAllCategories: React.Dispatch<React.SetStateAction<CategoryDetails[]>>;
  setIsDelete: React.Dispatch<React.SetStateAction<boolean>>; 
}

export const WithMaterialUI: React.FC<Props> = ({ AllCategories, setAllCategories ,setIsDelete}) => {
  const [allChapterGroups, setAllChapterGroups] = useState([] as ChapterGroupDetails[]);
  const [AllIcons, setAllIcons] = useState([] as IconDetails[]);
  const [userRoles, setUserRoles] = useState(["INVENTORY_ADMIN", "SUB_ADMIN","CUSTOMER_SUPPORT"]);
  const [role, setRole] = useState('');
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [shops, setShops] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
    
  }, []);


  const fetchShops = async (searchText: string) => {
    try {
      const response = await InventoryService.getAllShopsLatest(searchText);
      return response.data;
    } catch (error) {
      console.error('Error fetching shops:', error);
      return { success: false, data: [], error: error.message };
    }
  };
  
  // const handleShopToggle = (value: string) => () => {
  //   const currentIndex = formik.values.shopIds.indexOf(value);
  //   const newChecked = [...formik.values.shopIds];

  //   if (currentIndex === -1) {
  //     newChecked.push(value);
  //   } else {
  //     newChecked.splice(currentIndex, 1);
  //   }

  //   formik.setFieldValue('shopIds', newChecked);
  // };

  // const handleSearchChange = (event: any) => {
  //   console.log("handle search term clicked",event.target.value)
  //   setSearchTerm(event.target.value);
  //   fetchShops(event.target.value).then((response) => {
  //     if (response.success) {
  //       setShops(response.data.shops);
  //     }
  //   });
  // };

  // const clearSearch = () => {
  //   setSearchTerm('');
  //  // getInitialData();
  // };
const handleRoleChange = (event) => {
  const selectedRole = event.target.value;
  setRole(selectedRole); 
  formik.setFieldValue('role', selectedRole); 
};
  const getInitialData = async () => {
    try {
      const shopsResponse = await fetchShops('');
        setShops(shopsResponse.shops);
      // const iconsResponse = await ManagementServices.getAllIcons();
      // if (iconsResponse.success) {
      //   setAllIcons(iconsResponse.data);
      // }
      // const chapterGroupsResponse = await ManagementServices.getAllChapterGroups();
      // if (chapterGroupsResponse.success) {
      //   setAllChapterGroups(chapterGroupsResponse.data);
      // }
      const currenciesResponse = await ManagementServices.getAllCurrencyList();
      if (currenciesResponse.success) {
        const filteredCurrencies = currenciesResponse.data.filter(
          (item) => item.currencyName === 'LKR' || item.currencyName === 'USD'
        );
        setCurrencies(filteredCurrencies);
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    getInitialData();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const createNewUser = (values) => {
    setOpen(false);
    const data = {
      email: values.email,
      password: values.password,
      phoneNumber: values.contactNumber,
      role: values.role,
      name: values.userName,
      verifiedStatus: values.verifiedStatus,
      currency: values.currency,
      shopIds: values.shopIds
    };

    ManagementServices.createInventoryUser(data).then((res) => {
      if (res.success) {
        setIsDelete(true);
        Swal.fire('New User Added');
        setRole("")
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00'
        });
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      userName: '',
      email: '',
      contactNumber: '',
      password: '',
      role: '',
      verifiedStatus: '',
      currency: '',
      shops: [],
    },  
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createNewUser(values);
      formik.resetForm();
    } 
  });


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
 const filteredShops = shops.filter((shop) =>
   shop.shopName.toLowerCase().includes(searchTerm.toLowerCase())
 );

 const handleSearchChange = (event) => {
   setSearchTerm(event.target.value);
 };

 const clearSearch = () => {
   setSearchTerm('');
 };

 const handleShopToggle = (shopId) => (event) => {
   const currentIndex = formik.values.shops.indexOf(shopId);
   const newChecked = [...formik.values.shops];

   if (currentIndex === -1) {
     newChecked.push(shopId);
   } else {
     newChecked.splice(currentIndex, 1);
   }

   formik.setFieldValue('shopIds', newChecked);
  };
  const handleShopsChange = (event: any) => {
    formik.setFieldValue('shops', event.target.value as string[]);
  };
  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create New User
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography variant="h4" fontWeight="bold">
                    Create a New Admin User
                </Typography>
        </BootstrapDialogTitle> */}
        <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
          Create New User
        </Typography>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="userName"
              name="userName"
              label="User Name"
              value={formik.values.userName}
              onChange={formik.handleChange}
              error={formik.touched.userName && Boolean(formik.errors.userName)}
              helperText={formik.touched.userName && formik.errors.userName}
            />

            <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />

            <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="contactNumber"
              name="contactNumber"
              label="Contact Number"
              value={formik.values.contactNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.contactNumber &&
                Boolean(formik.errors.contactNumber)
              }
              helperText={
                formik.touched.contactNumber && formik.errors.contactNumber
              }
            />

            <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <div style={{ height: 20 }}></div>
            <InputLabel>Select Currency</InputLabel>
            <Select
              fullWidth
              id="currency"
              name="currency"
              value={formik.values.currency}
              onChange={formik.handleChange}
              error={formik.touched.currency && Boolean(formik.errors.currency)}
            >
              {currencies.map((currency) => (
                <MenuItem key={currency._id} value={currency._id}>
                  {currency.currencyName}
                </MenuItem>
              ))}
            </Select>
            <div style={{ height: 20 }}></div>
            <InputLabel>Select Role</InputLabel>
            {/* <Select
              fullWidth
              id="role"
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
              error={formik.touched.role && Boolean(formik.errors.role)}
            > */}
            <Select
              labelId="role-label"
              id="role"
              name="role"
              value={role}
              onChange={handleRoleChange}
              error={formik.touched.role && Boolean(formik.errors.role)}
              sx={{ width: '100%' }}
            >
              {userRoles.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            {role === 'CUSTOMER_SUPPORT' ? (
              <></>
            ) : (
              <>
                <div style={{ height: 20 }}></div>
                <FormControl fullWidth>
                  <InputLabel>Select Shops</InputLabel>
                  <div style={{ height: 5 }}></div>
                  <Select
                    fullWidth
                    multiple
                    value={formik.values.shops}
                    onChange={handleShopsChange}
                    renderValue={(selected) =>
                      (selected as string[])
                      .map(
                        (shopId) =>
                          shops.find((shop) => shop._id === shopId)?.shopName ||
                          ''
                      )
                      .join(', ')
                    }
                  >
                    {/* <Select
                    multiple
                    value={formik.values.shops}
                    onChange={handleShopsChange}
                    renderValue={(selected) =>
                      selected
                      .map(
                        (shopId) =>
                          shops.find((shop) => shop._id === shopId).shopName
                      )
                      .join(', ')
                    }
                  > */}
                    {/* <>
                  <MenuItem>
                 
                  </MenuItem>
                </> */}
                    <TextField
                      fullWidth
                      placeholder="Search Shops"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={clearSearch} size="small">
                            <CloseIcon />
                          </IconButton>
                        )
                      }}
                    />
                    {shops.map((shop) => (
                      <MenuItem key={shop._id} value={shop._id}>
                        <Checkbox
                          onChange={handleShopToggle(shop._id)}
                          checked={formik.values.shops.indexOf(shop._id) > -1}
                        />
                        <ListItemText primary={shop.shopName} />
                      </MenuItem>
                    ))}
                    {shops.length === 0 && (
                      <MenuItem disabled>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          align="center"
                        >
                          No shops found
                        </Typography>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </>
            )}
            {/* <FormControl fullWidth>
              <InputLabel>Select Shops</InputLabel>
              <div style={{ height: 5 }}></div>
              <Select
                multiple
                value={formik.values.shops}
                onChange={formik.handleChange}
                renderValue={(selected) =>
                  selected
                  .map((shopId) => {
                    const shop = shops.find((shop) => shop._id === shopId);
                    return shop ? shop.shopName : '';
                  })
                  .join(', ')
                }
                MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
              >
      
                <MenuItem disabled>
                  <TextField
                    fullWidth
                    placeholder="Search Shops"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={clearSearch} size="small">
                          <CloseIcon />
                        </IconButton>
                      )
                    }}
                  />
                </MenuItem>

                {filteredShops.length > 0 ? (
                  filteredShops.map((shop) => (
                    <MenuItem key={shop._id} value={shop._id}>
                      <Checkbox
                        checked={formik.values.shops.indexOf(shop._id) > -1}
                        onChange={handleShopToggle(shop._id)}
                      />
                      <ListItemText primary={shop.shopName} />
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="center"
                    >
                      No shops found
                    </Typography>
                  </MenuItem>
                )}
              </Select>
            </FormControl> */}
            <div style={{ height: 20 }}></div>
            <InputLabel>Verified Status</InputLabel>
            <Select
              fullWidth
              id="verifiedStatus"
              name="verifiedStatus"
              value={formik.values.verifiedStatus}
              onChange={formik.handleChange}
              error={
                formik.touched.verifiedStatus &&
                Boolean(formik.errors.verifiedStatus)
              }
            >
              <MenuItem key="VERIFIED" value="VERIFIED">
                VERIFIED
              </MenuItem>
              <MenuItem key="PENDING" value="PENDING">
                PENDING
              </MenuItem>
            </Select>

            <div style={{ height: 20 }}></div>

            <Button color="primary" variant="contained" type="submit" fullWidth>
              Create
            </Button>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

import axios from 'axios';
import { CategoryDetails } from 'src/models/Category';
import { ChapterDetails } from 'src/models/Chapter';
import { ChapterGroupDetails } from 'src/models/ChapterGroup';
import { ItemDetails } from 'src/models/Item';
import { UserDetails } from 'src/models/User';
import { AppResponse } from '../models/Response';
import { Util } from '../Util';
import { IconDetails } from './../models/Icon';
import { ActiveUserDetails } from 'src/models/ActiveUser';
import { CurrencyDetails } from 'src/models/Currency';
import { ShopsDetails } from 'src/models/Shops';

export class ManagementServices {
  public static async getAllUsers(
    limit?: number,
    offset?: number
  ): Promise<AppResponse<UserDetails[]>> {
    const url = Util.apiAuthUrl(`get-all-users`);
    const res = await axios.get<void, AppResponse<UserDetails[]>>(url);
    return res;
  }

  public static async getAllUsersSearch(limit: any, searchData: any, offset = 1 ): Promise<AppResponse<any[]>> {
  const url = Util.apiAuthUrl(`all-users-admin/search/${limit}/${offset}`);
  const res = await axios.post<Partial<any>, AppResponse<any[]>>(url, searchData);
  return res;
  }
  
  public static async getAllActiveUsers(
    limit?: number,
    offset?: number
  ): Promise<AppResponse<ActiveUserDetails[]>> {
    const url = Util.apiAuthUrl(`get-all-active-users`);
    const res = await axios.get<void, AppResponse<ActiveUserDetails[]>>(url);
    return res;
  }
  
  public static async getAllActiveUsersSearch(limit: any, searchData: any, offset = 1 ): Promise<AppResponse<any[]>> {
  const url = Util.apiAuthUrl(`getAllActiveUsers/search/${limit}/${offset}`);
  const res = await axios.post<Partial<any>, AppResponse<any[]>>(url, searchData);
  return res;
  }

  public static async getAllChaptersWithUserIdbyAdmin(
    clientId: any
  ): Promise<AppResponse<ChapterDetails[]>> {
    const url = Util.apiAuthUrl(`getAllChaptersWithUserIdbyAdmin/${clientId}`);
    const res = await axios.get<void, AppResponse<ChapterDetails[]>>(url);
    return res;
  }

  public static async getAllItemsWithChapterIdbyAdmin(
    chapterId: any
  ): Promise<AppResponse<ItemDetails[]>> {
    const url = Util.apiAuthUrl(`get-all-items-with-chapter-id-by-admin/${chapterId}`);
    const res = await axios.get<void, AppResponse<ItemDetails[]>>(url);
    return res;
  }

  public static async updateUser(data: any): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl(`update-customer-by-admin`);
    const res = await axios.post<void, AppResponse<UserDetails>>(url, data);
    return res;
  }
  
  public static async updateUserWebAccess(data: any): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl(`update-customer-web-access-by-admin`);
    const res = await axios.post<void, AppResponse<UserDetails>>(url, data);
    return res;
  }


  public static async deleteUser(
    UserId: any
  ): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl(`delete-user/${UserId}`);
    const res = await axios.delete<void, AppResponse<UserDetails>>(url);
    return res;
  }

    public static async deleteSubAdmin(
    UserId: any
  ): Promise<AppResponse<UserDetails>> {
    const url = Util.apiAuthUrl(`delete/subAdmin/${UserId}`);
    const res = await axios.delete<void, AppResponse<UserDetails>>(url);
    return res;
  }

  public static async getAllChapters(
    limit?: number,
    offset?: number
  ): Promise<AppResponse<ChapterDetails[]>> {
    const url = Util.apiAuthUrl(`getAllChaptersbyadmin`);
    const res = await axios.get<void, AppResponse<ChapterDetails[]>>(url);
    return res;
  }

  public static async getAllCategories(
    limit?: number,
    offset?: number
  ): Promise<AppResponse<CategoryDetails[]>> {
    const url = Util.apiAuthUrl(`get-all-categories-by-admin`);
    const res = await axios.get<void, AppResponse<CategoryDetails[]>>(url);
    return res;
  }

  public static async getAllClientsByDateRange(
    startDate: String,
    endDate: String
  ): Promise<AppResponse<UserDetails[]>> {
    const url = Util.apiAuthUrl(
      `get-all-clients-by-date-range/${startDate}/${endDate}`
    );
    const res = await axios.get<void, AppResponse<UserDetails[]>>(url);
    return res;
  }

  public static async getAllChaptersByDateRange(
    startDate: String,
    endDate: String
  ): Promise<AppResponse<ChapterDetails[]>> {
    const url = Util.apiAuthUrl(
      `get-all-chapters-by-date-range/${startDate}/${endDate}`
    );
    const res = await axios.get<void, AppResponse<ChapterDetails[]>>(url);
    return res;
  }

  public static async searchChaptersByChapterName( chapterName:any ): Promise<AppResponse<ChapterDetails[]>> {
    const url = Util.apiAuthUrl(`search-chapter-by-user-id`);
    const res = await axios.post<void, AppResponse<ChapterDetails[]>>(url,chapterName);
    return res;
  }


  public static async updateCategoryStatus(
    data: any
  ): Promise<AppResponse<CategoryDetails>> {
    const url = Util.apiAuthUrl(`update-category`);
    const res = await axios.post<void, AppResponse<CategoryDetails>>(url, data);
    return res;
  }

  public static async createCategory(
    data: any
  ): Promise<AppResponse<CategoryDetails>> {
    const url = Util.apiAuthUrl(`create-category`);
    const res = await axios.post<void, AppResponse<CategoryDetails>>(url, data);
    return res;
  }

  public static async updateCategory(
    data: any
  ): Promise<AppResponse<CategoryDetails>> {
    const url = Util.apiAuthUrl(`update-category-byAdmin`);
    const res = await axios.post<void, AppResponse<CategoryDetails>>(url, data);
    return res;
  }

  public static async deleteCategory(
    categoryId: any
  ): Promise<AppResponse<CategoryDetails>> {
    const url = Util.apiAuthUrl(`delete-category/${categoryId}`);
    const res = await axios.delete<void, AppResponse<CategoryDetails>>(url);
    return res;
  }

  public static async getAllChapterGroups(
    limit?: number,
    offset?: number
  ): Promise<AppResponse<ChapterGroupDetails[]>> {
    const url = Util.apiAuthUrl(`groupsbyadmin`);
    const res = await axios.get<void, AppResponse<ChapterGroupDetails[]>>(url);
    return res;
  }

  public static async updateChapterGroup(
    data: any
  ): Promise<AppResponse<ChapterGroupDetails>> {
    const url = Util.apiAuthUrl(`update-group-status`);
    const res = await axios.post<void, AppResponse<ChapterGroupDetails>>(
      url,
      data
    );
    return res;
  }

  public static async createChapterGroup(
    data: any
  ): Promise<AppResponse<ChapterGroupDetails>> {
    const url = Util.apiAuthUrl(`create-chapter-group`);
    const res = await axios.post<void, AppResponse<ChapterGroupDetails>>(
      url,
      data
    );
    return res;
  }

  public static async deleteChapterGroup(
    groupId: any
  ): Promise<AppResponse<ChapterGroupDetails>> {
    const url = Util.apiAuthUrl(`delete-group/${groupId}`);
    const res = await axios.delete<void, AppResponse<ChapterGroupDetails>>(url);
    return res;
  }

  public static async createIcon(data: any): Promise<AppResponse<IconDetails>> {
    const url = Util.apiAuthUrl(`create-icon`);
    const res = await axios.post<void, AppResponse<IconDetails>>(url, data);
    return res;
  }

  public static async deleteIcon(
    iconId: any
  ): Promise<AppResponse<IconDetails>> {
    const url = Util.apiAuthUrl(`delete-icon/${iconId}`);
    const res = await axios.delete<void, AppResponse<IconDetails>>(url);
    return res;
  }

  public static async getAllIcons(
    limit?: number,
    offset?: number
  ): Promise<AppResponse<IconDetails[]>> {
    const url = Util.apiAuthUrl(`icons`);
    const res = await axios.get<void, AppResponse<IconDetails[]>>(url);
    return res;
  }

  public static async updateIcon(data: any): Promise<AppResponse<IconDetails>> {
    const url = Util.apiAuthUrl(`update-icon-byadmin`);
    const res = await axios.post<void, AppResponse<IconDetails>>(url, data);
    return res;
  }

  public static async updateIconstatus(
    data: any
  ): Promise<AppResponse<IconDetails>> {
    const url = Util.apiAuthUrl(`update-icon-status`);
    const res = await axios.post<void, AppResponse<IconDetails>>(url, data);
    return res;
  }

  public static async getAllUserCategories(
    limit?: number,
    offset?: number
  ): Promise<AppResponse<CategoryDetails[]>> {
    const url = Util.apiAuthUrl(`get-all-user-categories-by-admin`);
    const res = await axios.get<void, AppResponse<CategoryDetails[]>>(url);
    return res;
  }

  public static async deleteUserCategory(
    categoryId: any
  ): Promise<AppResponse<CategoryDetails>> {
    const url = Util.apiAuthUrl(`delete-category/${categoryId}`);
    const res = await axios.delete<void, AppResponse<CategoryDetails>>(url);
    return res;
  }

  public static async getAllCurrency(
    limit?: number,
    offset?: number
  ): Promise<AppResponse<CurrencyDetails[]>> {
    const url = Util.apiAuthUrl(`get/currencyList/${limit}/${offset}`);
    const res = await axios.get<void, AppResponse<CurrencyDetails[]>>(url);
    return res;
  }

  //get all customers
  public static async getAllCustomers(
    shopId: string,
    queryString: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`allCustomersByShop-admin/${shopId}?${queryString}`);
    const res = await axios.get<void, AppResponse<any>>(url); 
    return res;
  }
  
  public static async getCustomersbyShop(
    shopId: string,
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`allCustomers-admin/${shopId}`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }

  //get all invoice
  public static async getAllInvoice(
    shopId: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`all-invoices-by-shop/${shopId}`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }
  
  public static async getAllInvoicesBySearch(
  shopId: any , limit:any ,searchData:any ,offset = 1 
): Promise<AppResponse<any[]>> {
  const url = Util.apiAuthUrl(`all-invoices-by-shop-admin/search/${shopId}/${limit}/${offset}`);
  const res = await axios.post<Partial<any>, AppResponse<any[]>>(url, searchData);
  return res;
  }

  public static async getAllInvoicesBySearchAdmin(
  limit:any ,searchData:any ,offset = 1 
  ): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`all-invoices-by-admin/search/${limit}/${offset}`);
    const res = await axios.post<Partial<any>, AppResponse<any[]>>(url, searchData);
    return res;
    }

  //get all invoice items
  public static async getAllInvoiceItems(
    invoiceId: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`all-invoice-items-by-admin/${invoiceId}`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }

  //Get currency list
  public static async getAllCurrencyList(
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`get-all-currency-admin`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }

  public static async getAllSubscriptonPlans(
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`all-subscriptions-plan`);
    const res = await axios.get<void, AppResponse<any>>(url);
    return res;
  }

  public static async createAdminUser(
    data: any
  ): Promise<AppResponse<CategoryDetails>> {
    const url = Util.apiAuthUrl(`create-category`);
    const res = await axios.post<void, AppResponse<CategoryDetails>>(url, data);
    return res;
  }

  public static async createInventoryUser(
    data: any
  ): Promise<AppResponse<CategoryDetails>> {
    const url = Util.apiAuthUrl(`create-inventory-user`);
    const res = await axios.post<void, AppResponse<UserDetails>>(url, data);
    return res;
  }

  public static async getAllAdminUsers(
    limit?: number,
    offset?: number
  ): Promise<AppResponse<UserDetails[]>> {
    const url = Util.apiAuthUrl(`get-all-admin-users/${limit}/${offset}`);
    const res = await axios.get<void, AppResponse<UserDetails[]>>(url);
    return res;
  }

  public static async getAllAdminUsersSearch(limit: any, searchData: any, offset = 1 ): Promise<AppResponse<any[]>> {
    const url = Util.apiAuthUrl(`get-all-admin-users/search/${limit}/${offset}`);
    const res = await axios.post<Partial<any>, AppResponse<any[]>>(url, searchData);
    return res;
    }

  //delete invoice
  public static async deleteInvoice(
    invoiceId: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`delete-invoice-admin/${invoiceId}`);
    const res = await axios.delete<void, AppResponse<any>>(url);
    return res;
  }

   //delete inventory
   public static async deleteInventory(
    inventoryId: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`delete-inventory-admin/${inventoryId}`);
    const res = await axios.delete<void, AppResponse<any>>(url);
    return res;
  }

  public static async updateInventoryUser(
    data: any
  ): Promise<AppResponse<CategoryDetails>> {
    const url = Util.apiAuthUrl(`update-inventory-user`);
    const res = await axios.post<void, AppResponse<any>>(url, data);
    return res;
  }

  public static async getShopsByIds(shopIds: string[]): Promise<ShopsDetails[]> {
    try {
      const url = Util.apiAuthUrl('get-shops-by-ids');
      const res = await axios.post<ShopsDetails[]>(url, { shopIds });
      return res.data; // Return only the data from the response
    } catch (error) {
      throw new Error(`Error fetching shops: ${error.message}`);
    }
  }

  public static async changePassword(
    data: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`change-user-password-admin`);
    const res = await axios.post<void, AppResponse<UserDetails>>(url, data);
    return res;
  }
  
   //delete invoice
   public static async deleteShop(
    shopId: any,
    deleteOption: string
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`delete-shop-admin/${shopId}/${deleteOption}`);
    
    const res = await axios.delete<void, AppResponse<any>>(url);
    return res;
  }

   public static async sendMessagesByAdmin(
    data: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`send-messages-by-admin`);
    const res = await axios.post<void, AppResponse<any>>(url, data);
    return res;
  }

  public static async sendEmailsByAdmin(
    data: any
  ): Promise<AppResponse<any>> {
    const url = Util.apiAuthUrl(`send-emails-by-admin`);
    const res = await axios.post<void, AppResponse<any>>(url, data);
    return res;
  }

}

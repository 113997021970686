import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageHeader from './PageHeader';
import { useState } from 'react';
import { TemplateCategoryDetails } from 'src/models/TemplateCategory';
import Footer from 'src/components/Footer';
import { Container, Grid } from '@mui/material';
import InventoryTable from './InvoiceTable';

const InvoiceView = () => {
  const [TemplateCategories, setTemplateCategories] = useState(
    [] as TemplateCategoryDetails[]
  );

  return (
    <>
      <Helmet>
        <title>All Invoices</title>
      </Helmet>
      <div style={{ height: 20 }}></div>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={0}
        >
          <Grid item xs={12} md={12}>
            <InventoryTable/>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default InvoiceView;

import { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Card,
  Box,
  CardHeader,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Typography,
  Tooltip,
  IconButton,
  TablePagination,
  useTheme,
  styled,
  TextField,
  InputAdornment,
  Button,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CopyAllTwoTone from '@mui/icons-material/CopyAllTwoTone';
import { UserDetails, VerifyStatus } from 'src/models/User';
import { ManagementServices } from 'src/services/ManagementServices';
import Swal from 'sweetalert2';
import { CategoryDetails } from 'src/models/Category';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './../../ToastStyle.css';
import { WithMaterialUI } from './create-admin-user-popup';
import AdminUserEdit from './adminUserEdit';
import SearchIcon from '@mui/icons-material/Search';

interface Props {
  AllCategories: CategoryDetails[];
  setAllCategories: React.Dispatch<React.SetStateAction<CategoryDetails[]>>;
}

const AllAdminUsersTable: FC<Props> = ({ AllCategories, setAllCategories }) => {
  const theme = useTheme();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [users, setUsers] = useState([]);
  const [lazyLoadingOffset, seTlazyLoadingOffset] = useState(0);
  const [offsetIncrease, setOffsetIncrease] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] =
    useState<UserDetails | null>(null);
  const [ifSearchChanged, setIfSearchChanged] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const initialState = {
    data: ''
  };
  const [searchData, setSearchData] = useState<any>(initialState);
  const data = {
    searchTag: searchKeyword
  };
  const [userCount, setUserCount] = useState(null);
  const [isSearchFilter, setIsSearchFilter] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!isDelete) {
      fetchAdminUsers();
    }
  }, [limit, page, isSearchFilter]);

  useEffect(() => {
    if (isDelete) {
      setPage(0);
      seTlazyLoadingOffset(1);
      fetchAdminUsers();
    }
    if (isSearch) {
      setPage(0);
      seTlazyLoadingOffset(1);
      fetchAdminUsers();
    }
  }, [isDelete, isSearch]);

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const fetchAdminUsers = async () => {
    let inventoryOffset = lazyLoadingOffset;
    if (!ifSearchChanged) {
      inventoryOffset = offsetIncrease
        ? lazyLoadingOffset + 1
        : lazyLoadingOffset - 1;
    }
    if (isDelete || isSearch) {
      inventoryOffset = 1;
    }
    try {
      const res = await ManagementServices.getAllActiveUsersSearch(
        limit,
        data,
        inventoryOffset
      );
      if (res.success) {
        seTlazyLoadingOffset(inventoryOffset);
        if(isSearch  ||ifSearchChanged || isDelete){
          const userList = res.data[0]?.userList || [];
          setUsers(userList);
        }
        else{
          const userList = res.data[0]?.userList || [];
          setUsers(prevUsers => [...prevUsers, ...userList]); 
        }

        const count = res.data[0]?.count || 0;
        setUserCount(count);
        setIfSearchChanged(false);
        setIsSearch(false);
        setIsDelete(false);
        setLoading(false);
       // setUsers(userList);
      }
    } catch (error) {
      console.error('Error fetching admin users:', error);
    }
    finally {
      setIsLoading(false);
    }
  };
  const handleCopy = async (categoryId) => {
    await navigator.clipboard.writeText(categoryId);
    toast('Copied to clipboard');
  };

  const handleEditOpen = (
    userId: string,
    name: string,
    email: string,
    phoneNumber: string,
    role: string,
    shops: string[],
    currency: string
  ) => {
        setSelectedUserDetails({
          _id: userId,
          name,
          email,
          phoneNumber,
          role,
          shops,
          currency
        });
    setOpenEdit(true);

  };

  const deleteConfirm = (user: any,index : number) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Close'
    }).then((result) => {
      if (result.isConfirmed) {
        if (user?.role == 'SUB_ADMIN') {
               ManagementServices.deleteSubAdmin(user?._id)
               .then((res) => {
                 Swal.fire(
                   'Deleted!',
                   'User has been deleted.',
                   'success'
                 ).then((result) => {
                   if (result.isConfirmed) {
                     // setIsDelete(true);
                     const updatedUserList = [...users];
                     updatedUserList.splice(index, 1);
                     setUsers(updatedUserList);
                   }
                 });
               })
               .catch((error) => {
                 Swal.fire(
                   'Error!',
                   'There was an error deleting the user.',
                   'error'
                 );
               });
        } else {
            ManagementServices.deleteUser(user?._id)
            .then((res) => {
              Swal.fire('Deleted!', 'User has been deleted.', 'success').then(
                (result) => {
                  if (result.isConfirmed) {
                    // setIsDelete(true);
                    const updatedUserList = [...users];
                    updatedUserList.splice(index, 1);
                    setUsers(updatedUserList);
                  }
                }
              );
            })
            .catch((error) => {
              Swal.fire(
                'Error!',
                'There was an error deleting the user.',
                'error'
              );
            });
           }
      
      }
    });
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setLoading(true);
    const increaseOffset = page > newPage ? false : true;
    setOffsetIncrease(increaseOffset);
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
    setPage(0);
    seTlazyLoadingOffset(0);
  };

  const handleSearch = () => {
    setIsSearch(true);
    setIsSearchFilter(true);
  };
  const handleSearchClear = () => {
    setIsSearchFilter(false);
    setPage(0);
    seTlazyLoadingOffset(1);
    setIfSearchChanged(true);
    setSearchKeyword('');
    setSearchData(initialState);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Typography
              variant="h4"
              component="h2"
              sx={{
                fontSize: '2rem',
                fontWeight: 'bold'
              }}
            >
              All Admin Users
            </Typography>
          }
          action={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 2
              }}
            >
              <WithMaterialUI
                AllCategories={AllCategories}
                setAllCategories={setAllCategories}
                setIsDelete={setIsDelete}
              />
              <Box ml={2} mr={2} mt={1}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search users..."
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleSearch} color="primary">
                          <SearchIcon />
                        </IconButton>
                        {isSearchFilter && (
                          <IconButton onClick={handleSearchClear} color="error">
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </Box>
          }
        />
        <Divider />
        {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <CircularProgress />
        </Box>
      ) :(
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>No</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>User Role</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography
                      variant="h4"
                      component="h2"
                      sx={{
                        fontSize: '1rem',
                        fontWeight: 'bold'
                      }}
                    >
                      No data to display
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                users.map((user, index) => (
                  <TableRow key={user._id} hover>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{user._id}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell align="right">
                     <Box display="flex" justifyContent="end">
                     <Tooltip title="Copy ID" arrow>
                        <IconButton
                          onClick={() => handleCopy(user._id)}
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.warning.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <CopyAllTwoTone fontSize="small" />
                          <ToastContainer
                            position="bottom-right"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick={false}
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover={false}
                            theme="dark"
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit User" arrow>
                        <IconButton
                          onClick={() =>
                            handleEditOpen(
                              user._id,
                              user.name,
                              user.email,
                              user.phoneNumber,
                              user.role,
                              user.shops,
                              user.currency
                            )
                          }
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete User" arrow>
                        <IconButton
                          onClick={() => deleteConfirm(user,index)}
                          sx={{
                            '&:hover': {
                              background: theme.colors.error.lighter
                            },
                            color: theme.palette.error.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                     </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>)}
        
        {users.length === 0 ? (
                    <Typography >
                    </Typography>
              ) : (
        <Box p={2}  display="flex" justifyContent="center">
       {
        userCount > users.length && (
          <Button
          variant="contained"
          color="primary"
          onClick={(e) => handlePageChange(e, page + 1)}
          >
          {loading ? 'Loading...' : 'See More'}
        </Button>
        )
       }
          {/* <TablePagination
            component="div"
            count={userCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[20, 30, 50, 60]}
          /> */}
        </Box>)}
        <AdminUserEdit
          open={openEdit}
          onClose={handleEditClose}
          selectedUser={selectedUserDetails}
          setIsDelete={setIsDelete}
        />
      </Card>
    </>
  );
};

export default AllAdminUsersTable;

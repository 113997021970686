import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
    styled,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemText,
    SelectChangeEvent,
  } from '@mui/material';
  import { useEffect, useState } from 'react';
  import CloseIcon from '@mui/icons-material/Close';
  import * as yup from 'yup';
  import { useFormik } from 'formik';
  import Swal from 'sweetalert2';
  import { InventoryService } from 'src/services/InventoryService';
  import { ManagementServices } from 'src/services/ManagementServices';
  import { CurrencyDetails } from 'src/models/Currency';
  import { UserDetails } from 'src/models/User';
  
  interface AdminUserEditProps {
    open: boolean;
    onClose: () => void;
    selectedUser: UserDetails | null;
    setIsDelete:any
  }
  
  function AdminUserEdit({ open, onClose, selectedUser, setIsDelete }: AdminUserEditProps) {
    const [currencies, setCurrencies] = useState<CurrencyDetails[]>([]);
    const [shops, setShops] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [userRoles, setUserRoles] = useState<string[]>([
      'INVENTORY_ADMIN',
      'SUB_ADMIN',
      'CUSTOMER_SUPPORT'
    ]);
    const [role, setRole] = useState('');
    useEffect(() => {
      
      if (selectedUser) {
        const getCurrencyList = async () => {
          try {
            const res = await ManagementServices.getAllCurrencyList();
            if (res.success) {
              const filteredCurrencies = res.data.filter(
                (item) =>
                  item.currencyName === 'LKR' || item.currencyName === 'USD'
              );
              setCurrencies(filteredCurrencies);
            }
          } catch (error) {
            console.log('Failed to fetch currency list:', error);
          }
        };

        const fetchShops = async (searchText: string) => {
          try {
            const response = await InventoryService.getAllShopsLatest(
              searchText
            );
            if (response.success) {
              setShops(response.data.shops);
            }
          } catch (error) {
            console.error('Error fetching shops:', error);
          }
        };
        getCurrencyList();
        fetchShops('');
        setRole(selectedUser?.role);
      }
      //}
    }, [selectedUser]);
  
    const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      const response = await InventoryService.getAllShopsLatest(
        event.target.value
      );
      if (response.success) {
        setShops(response.data.shops);
      }
    };
  
    const clearSearch = async () => {
      setSearchTerm('');
      const response = await InventoryService.getAllShopsLatest('');
      if (response.success) {
        setShops(response.data.shops);
      }
    };
  
    const validationSchema = yup.object({
      name: yup.string().required('Required'),
      email: yup.string().required('Required'),
      phoneNumber: yup.string().required('Required'),
      role: yup.string().required('Required'),
      currency: yup.string().required('Required'),
      shops: yup.array().of(yup.string()).required('Required'),
    });
  
    const formik = useFormik({
      
      initialValues: {
        id: selectedUser?._id || '',
        name: selectedUser?.name || '',
        email: selectedUser?.email || '',
        phoneNumber: selectedUser?.phoneNumber || '',
        role: role || userRoles,
        currency: selectedUser?.currency || currencies,
        shops: selectedUser?.shops || shops
      },
      validationSchema: validationSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
        updateShop(values);
        formik.resetForm();
      }
    });
  
    const updateShop = (values: any) => {
      onClose();
      const data = {
        id: selectedUser?._id,
        name: values.name,
        email: values.email,
        phoneNumber: values.phoneNumber,
        role: values.role,
        currency: values.currency,
        shops: values.shops,
      };
  
      ManagementServices.updateInventoryUser(data).then((res) => {
        if (res.success) {
          Swal.fire('User Updated', 'Successfully', 'success');
          setIsDelete(true)
        } else {
          Swal.fire({
            icon: 'error',
            title: res.error,
            confirmButtonColor: '#FD7F00',
          });
        }
      });
    };
  
    const handleShopsChange = (event: SelectChangeEvent<string[]>) => {
      formik.setFieldValue('shops', event.target.value as string[]);
    };
  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setRole(selectedRole); 
    formik.setFieldValue('role', selectedRole); 
  };
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="h4" fontWeight="bold">
            Edit User Details
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          {selectedUser ? (
            <form onSubmit={formik.handleSubmit}>
              <Typography>User Name</Typography>
              <TextField
                required
                id="UserName"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <div style={{ height: 20 }}></div>
              <Typography>Email</Typography>
              <TextField
                required
                id="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                fullWidth
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <div style={{ height: 20 }}></div>
              <Typography>Phone Number</Typography>
              <TextField
                required
                id="PhoneNumber"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                fullWidth
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
              <div style={{ height: 20 }}></div>
              <InputLabel>Select Currency</InputLabel>
              <Select
                fullWidth
                id="currency"
                name="currency"
                value={formik.values.currency}
                onChange={formik.handleChange}
                error={
                  formik.touched.currency && Boolean(formik.errors.currency)
                }
                renderValue={(selected) =>
                  selected
                    ? currencies.find((currency) => currency._id === selected)
                        ?.currencyName
                    : ''
                }
              >
                {currencies.map((currency) => (
                  <MenuItem key={currency._id} value={currency._id}>
                    {currency.currencyName}
                  </MenuItem>
                ))}
              </Select>

              <div style={{ height: 20 }}></div>
              <InputLabel>Select Role</InputLabel>
              {/* <Select
                fullWidth
                id="role"
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
              > */}
              <Select
                labelId="role-label"
                id="role"
                name="role"
                value={role}
                onChange={handleRoleChange}
                error={formik.touched.role && Boolean(formik.errors.role)}
                sx={{ width: '100%' }}
              >
                {userRoles.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              {role === 'CUSTOMER_SUPPORT' ? (
                <></>
              ) : (
                <>
                  <div style={{ height: 20 }}></div>

                  <InputLabel>Select Shops</InputLabel>
                  <Select
                    fullWidth
                    multiple
                    value={formik.values.shops}
                    onChange={handleShopsChange}
                    renderValue={(selected) =>
                      (selected as string[])
                      .map(
                        (shopId) =>
                          shops.find((shop) => shop._id === shopId)?.shopName ||
                          ''
                      )
                      .join(', ')
                    }
                  >
                    <MenuItem>
                      <TextField
                        fullWidth
                        placeholder="Search Shops"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={clearSearch} size="small">
                              <CloseIcon />
                            </IconButton>
                          )
                        }}
                      />
                    </MenuItem>
                    {shops.map((shop) => (
                      <MenuItem key={shop._id} value={shop._id}>
                        <Checkbox
                          checked={formik.values.shops.includes(shop._id)}
                        />
                        <ListItemText primary={shop.shopName} />
                      </MenuItem>
                    ))}
                    {shops.length === 0 && (
                      <MenuItem disabled>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          align="center"
                        >
                          No shops found
                        </Typography>
                      </MenuItem>
                    )}
                  </Select>
                </>
              )}
              <div style={{ height: 20 }}></div>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Update
              </Button>
            </form>
          ) : (
            <Typography variant="body1">Loading...</Typography>
          )}
        </DialogContent>
      </Dialog>
    );
  }
  
  export default AdminUserEdit;
  
import React, { createContext, useState } from 'react';

interface Props {
  children: React.ReactNode;
}

interface SelectedShopProps {
  isButtonClicked: boolean;
  setButtonClicked: (isClicked: boolean) => void;
}

export const SelectedShop = createContext<SelectedShopProps>({
  isButtonClicked: false,
  setButtonClicked: () => {}
});

export const SelectedShopProvider: React.FC<Props> = ({ children }) => {
  const [isButtonClicked, setButtonClicked] = useState(false);

  return (
    <SelectedShop.Provider value={{ isButtonClicked, setButtonClicked }}>
      {children}
    </SelectedShop.Provider>
  );
};

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AuthService } from 'src/services/AuthService';
import { UserDetails } from 'src/models/User';
import { ManagementServices } from 'src/services/ManagementServices';
import { ShopsDetails } from 'src/models/Shops';
import { SelectedShop } from 'src/contexts/SelectedShop';

function HeaderButtons() {
  const [selectedShop, setSelectedShop] = useState('');
  const [isUser, setUser] = useState({} as UserDetails);
  const [userShops, setUserShops] = useState<ShopsDetails[]>([]); 
  const { isButtonClicked, setButtonClicked } = useContext(SelectedShop);
  useEffect(() => {
    AuthService.getMe().then((res: any) => {
      if (res.success) {
        setUser(res.data);
     
        if (res.data.shops && res.data.shops.length > 0) {
          fetchShops(res.data.shops);
        }
      }
    });
  }, []);

  const fetchShops = async (shops: string[]) => {
    try {
      const shopsData = await ManagementServices.getShopsByIds(shops);
      if (Array.isArray(shopsData)) {
        setUserShops(shopsData);
        const selectedShop = localStorage.getItem('selectedShop');

        if (selectedShop) {
            setSelectedShop(JSON.parse(
            localStorage.getItem('selectedShop'))._id);
            //setButtonClicked(!isButtonClicked);
        } else {
          localStorage.setItem('selectedShop', JSON.stringify(shopsData[0]));
          setSelectedShop(shopsData.length > 0 ? shopsData[0]._id : '');
          setButtonClicked(!isButtonClicked);
        }
       
      } else {
        console.error('Invalid response format:', shopsData);
      }
    } catch (error) {
      console.error('Error fetching shops:', error);
    }
  };
   
  const handleShopChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value as string;
    setSelectedShop(selectedId);
    setButtonClicked(!isButtonClicked);
    const selectedShop = userShops.find((shop) => shop._id === selectedId);
    
     if (selectedShop) {
       localStorage.setItem('selectedShop', JSON.stringify(selectedShop));
     } 
  };

  return (
    <Box sx={{ mr: 1 }}>
      <Box sx={{ mx: 0.5 }} component="span">
      {isUser?.shops && userShops.length > 0  && (
            <FormControl fullWidth sx={{ mt: 0.5, width: '200px' }}>
              <InputLabel sx={{ width: '100%' }}>Select Shop</InputLabel>
              <div style={{ height: 5 }}></div>
              <Select
                value={selectedShop}
                onChange={handleShopChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Select Shop' }}
              >
                {userShops?.map((shop: ShopsDetails) => (
                  <MenuItem key={shop._id} value={shop._id}>
                    {shop.shopName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
      </Box>
    </Box>
  );
}

export default HeaderButtons;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { TemplateCategoryDetails } from 'src/models/TemplateCategory';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SketchPicker } from 'react-color';
import { InventoryService } from 'src/services/InventoryService';
import Swal from 'sweetalert2';
import { useState } from 'react';
import FormData from 'form-data';
import { ArrowDropDown } from '@mui/icons-material';
import Papa from 'papaparse';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton 
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function AddProduct({templateId, get}) {
  const [open, setOpen] = useState(false);

  
    
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };




  const formik = useFormik({
    initialValues: {
      sKUNumber: '',
      productName: '',
      price: '',
      quantity: '',
      brandName: '',
      categoryName: '',

    },
    validationSchema: yup.object({
      sKUNumber: yup.number().required('sKUNumber is required'),
      productName: yup.string().required('productName is required'),
      price: yup.number().required('price is required'),
      quantity: yup.number().required('quantity is required'),
      brandName: yup.string().required('brandName is required'),
      categoryName: yup.string().required('categoryName is required'),

    }),
    onSubmit: (values, { resetForm }) => {
      const data = {
        inventoryTemplateId:templateId,
        sKUNumber: values.sKUNumber,
        productName: values.productName,
        price: values.price,
        quantity: values.quantity,
        brandName: values.brandName,
        categoryName: values.categoryName
      };
      InventoryService.AddProductForTemplate(data).then((res) => {
       
        if (res.success) {

          setOpen(false);
          Swal.fire('Inventory created', 'Successfully', 'success').then((result) => {
            if (result.isConfirmed) {
              resetForm();
              get();
            }
          });
        } else {
          setOpen(false);
          Swal.fire({
            icon: 'error',
            title: res.error,
            confirmButtonColor: '#FD7F00',
          });
        }
      });
    }
  });


  return (
    <div>
      <Button variant="outlined" onClick={() => handleClickOpen()}>
        Add Product for template
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth maxWidth="md">
        {/* <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}>
          <Typography variant="h4" fontWeight="bold">
            Add a New Product for Inventory Template
          </Typography>

        </BootstrapDialogTitle> */}
        <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
            Add a New Product for Inventory Template
        </Typography>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>

            <TextField
              fullWidth
              id="sKUNumber"
              name="sKUNumber"
              label="SKU Number"
              value={formik.values.sKUNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.sKUNumber && Boolean(formik.errors.sKUNumber)}
              helperText={formik.touched.sKUNumber && formik.errors.sKUNumber}
            />
          <div style={{ height: 10 }}></div>
            <TextField
              fullWidth
              id="productName"
              name="productName"
              label="Product Name"
              value={formik.values.productName}
              onChange={formik.handleChange}
              error={
                formik.touched.productName && Boolean(formik.errors.productName)}
              helperText={formik.touched.productName && formik.errors.productName}
            />
          <div style={{ height: 10 }}></div>
            <TextField
              fullWidth
              id="price"
              name="price"
              label="Price"
              value={formik.values.price}
              onChange={formik.handleChange}
              error={
                formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
            />
          <div style={{ height: 10 }}></div>
            <TextField
              fullWidth
              id="quantity"
              name="quantity"
              label="Quantity"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              error={
                formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity && formik.errors.quantity}
            />
          <div style={{ height: 10 }}></div>
            <TextField
              fullWidth
              id="brandName"
              name="brandName"
              label="Brand Name"
              value={formik.values.brandName}
              onChange={formik.handleChange}
              error={
                formik.touched.brandName && Boolean(formik.errors.brandName)}
              helperText={formik.touched.brandName && formik.errors.brandName}
            />
          <div style={{ height: 10 }}></div>
            <TextField
              fullWidth
              id="categoryName"
              name="categoryName"
              label="Category Name"
              value={formik.values.categoryName}
              onChange={formik.handleChange}
              error={
                formik.touched.categoryName && Boolean(formik.errors.categoryName)}
              helperText={formik.touched.categoryName && formik.errors.categoryName}
            />


            <div style={{ height: 10 }}></div>

            <Button color="primary" variant="contained" type="submit" fullWidth>
              Add Product
            </Button>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default AddProduct;

import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextField, Autocomplete, InputAdornment } from '@mui/material';
import { ManagementServices } from 'src/services/ManagementServices';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { ChapterGroupDetails } from 'src/models/ChapterGroup';
import { IconDetails } from 'src/models/Icon';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { UserDetails } from 'src/models/User';
import { InventoryService } from 'src/services/InventoryService';
import { CurrencyDetails } from 'src/models/Currency';
import { SubscriptionDetails } from 'src/models/SubscriptionPlans';
import { format } from 'date-fns';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const validationSchema = yup.object({
  category: yup.string().required('Category Name is required')
});

export function CreateShop({
  AllCategories,
  setAllCategories,
  allShops,
  setAllShops
}) {
  const [allChapterGroups, setAllChapterGroups] = useState(
    [] as ChapterGroupDetails[]
  );
  const [AllIcons, setAllIcons] = useState([] as IconDetails[]);
  const [allUserss, setAllUsers] = useState([] as UserDetails[]);
  const [currency, setCurrency] = useState([] as CurrencyDetails[]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState(
    [] as SubscriptionDetails[]
  );
  const [timePeriod, setTimePeriod] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {}, []);

  const getCurrencyList = () => {
    ManagementServices.getAllCurrencyList().then((res) => {
      if (res) {
        const filteredCurrencies = res.data.filter(
          (item) => item.currencyName === 'LKR' || item.currencyName === 'USD'
        );
        setCurrency(filteredCurrencies);
      } else {
      }
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    getCurrencyList();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [bubbleColor, setBackgroundColor] = React.useState('#ffffff');
  const [isdropOpen, setIsdropOpen] = useState(false);
  const [file, setFile] = useState(null);

  const handlebackgroundColorChange = (color: any) => {
    setBackgroundColor(color.hex);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      shopName: '',
      shopRegisterNumber: '',
      ownerName: '',
      phoneNumber: '',
      shopAddress: ''
    },
    validationSchema: yup.object({
      email: yup.string().required('email is required'),
      password: yup.string().required('password is required'),
      shopName: yup.string().required('Shop Name is required'),
      shopRegisterNumber: yup.string().required('register number is required'),
      ownerName: yup.string().required('Owner Name is required'),
      // phoneNumber: yup.number().required('Phonbe Number is required').positive(),
      phoneNumber: yup
      .string()
      .test(
        'is-numeric',
        'Contact number must contain only digits',
        (value) => {
          return /^\d*$/.test(value);
        }
      )
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .required('Phone Number is required'),
      shopAddress: yup.string().required('Shop address is required')
    }),
    onSubmit: (values, { resetForm }) => {
      const data = {
        email: values.email,
        name: values.ownerName,
        password: values.password,
        shopName: values.shopName,
        shopRegisterNumber: values.shopRegisterNumber,
        ownerName: values.ownerName,
        phoneNumber: values.phoneNumber,
        shopAddress: values.shopAddress,
        currency: selectedCurrency,
        timePeriod: timePeriod
      };

      InventoryService.createShops(data).then((res) => {
        console.log(data, values);
        console.log(timePeriod);

        if (res.success) {
          const createdAtDate = new Date(res.data.createdAt);
          const endDate = new Date(createdAtDate);

          endDate.setFullYear(
            timePeriod?.name == 'Annually'
              ? createdAtDate.getFullYear() + 1
              : createdAtDate.getFullYear()
          );

          endDate.setMonth(
            timePeriod?.name == 'Monthly'
              ? createdAtDate.getMonth() + 1
              : createdAtDate.getMonth()
          );
          const startDateStr = res.data.createdAt;
          const endDateStr = endDate.toISOString();

          const startDateFormat = new Date(startDateStr);
          const endDateFormat = new Date(endDateStr);

          const formattedStartDate = format(startDateFormat, 'dd/MM/yyyy');
          const formattedEndDate = format(endDateFormat, 'dd/MM/yyyy');

          const data = {
            _id: res.data._id,
            createdBy: res.data.createdBy,
            userId: [res.data.createdBy],
            userEmail: values.email,
            name: values.ownerName,
            password: values.password,
            shopName: values.shopName,
            shopRegisterNumber: values.shopRegisterNumber,
            ownerName: values.ownerName,
            phoneNumber: values.phoneNumber,
            shopAddress: values.shopAddress,
            currency: selectedCurrency,
            subscriptionPlanName: timePeriod?.name,
            remainingDays: timePeriod?.name == 'Annually' ? 365 : 30,
            customerCount: 0,
            inventoryCount: 0,
            invoiceCount: 0,
            status: 'PENDING',
            userPhoneNumber: [],
            paymentType: 'BANK',
            paymentStatus: 'PAID',
            createdAt: res.data.createdAt,
            paymentStartDate: formattedStartDate,
            paymentEndDate: formattedEndDate
          };
          console.log('datanew alls', data, timePeriod?.name);
          setOpen(false);
          Swal.fire('Shop Created', 'Successfully', 'success').then(
            (result) => {
              if (result.isConfirmed) {
                const updatedTemplateProduct = [data, ...allShops];
                setAllShops(updatedTemplateProduct);
                resetForm();
              }
            }
          );
        } else {
          setOpen(false);
          Swal.fire('Shop Created Fail');
          resetForm();
        }
      });
    }
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Create New Shop
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
          Create a New Shop
        </Typography>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="E mail"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="shopName"
              name="shopName"
              label="Shop Name"
              value={formik.values.shopName}
              onChange={formik.handleChange}
              error={formik.touched.shopName && Boolean(formik.errors.shopName)}
              helperText={formik.touched.shopName && formik.errors.shopName}
            />

            <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="shopRegisterNumber"
              name="shopRegisterNumber"
              label="Shop Registration Number"
              value={formik.values.shopRegisterNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.shopRegisterNumber &&
                Boolean(formik.errors.shopRegisterNumber)
              }
              helperText={
                formik.touched.shopRegisterNumber &&
                formik.errors.shopRegisterNumber
              }
            />

            <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="ownerName"
              name="ownerName"
              label="Owner Name"
              value={formik.values.ownerName}
              onChange={formik.handleChange}
              error={
                formik.touched.ownerName && Boolean(formik.errors.ownerName)
              }
              helperText={formik.touched.ownerName && formik.errors.ownerName}
            />

            <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="Shop Phone Number"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            />

            <div style={{ height: 20 }}></div>
            <TextField
              fullWidth
              id="shopAddress"
              name="shopAddress"
              label="Shop Address"
              value={formik.values.shopAddress}
              onChange={formik.handleChange}
              error={
                formik.touched.shopAddress && Boolean(formik.errors.shopAddress)
              }
              helperText={
                formik.touched.shopAddress && formik.errors.shopAddress
              }
            />

            <div style={{ height: 20 }}></div>

            <Autocomplete
              fullWidth
              id="currency"
              options={currency}
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.currencyName || ''
              }
              onChange={(event, value) => setSelectedCurrency(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps
                    // endAdornment: null,
                  }}
                  label="Currency Type"
                />
              )}
            />

            <div style={{ height: 20 }}></div>

            {/* <Autocomplete
              fullWidth
              id="sub"
              options={[
                { name: 'Monthly', value: 'Monthly' },
                { name: 'Annually', value: 'Annually' }
              ]}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name || '')}
              onChange={(event, value) => setTimePeriod(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    // endAdornment: null,
                  }}
                  label="Subscription Plan"
                />
              )}
            /> */}
            <Autocomplete
              fullWidth
              id="sub"
              options={[
                { name: 'Monthly', value: 'Monthly' },
                { name: 'Annually', value: 'Annually' }
              ]}
              value={timePeriod}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(event, newValue) => setTimePeriod(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps
                  }}
                  label="Subscription Plan"
                />
              )}
            />

            <div style={{ height: 10 }}></div>

            <Button color="primary" variant="contained" type="submit" fullWidth>
              Create
            </Button>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
}

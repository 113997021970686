import {
  Box,
  Card,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  CircularProgress,Button
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { TemplateInventoryDetails } from 'src/models/InventoryDetails';
import { InventoryService } from 'src/services/InventoryService';
import { toast } from 'react-toastify';
import PreviewIcon from '@mui/icons-material/Preview';
import UserContext from 'src/context/UserContext';
import { styled } from '@mui/material/styles';
import UploadIcon from '@mui/icons-material/Upload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import EditProduct from 'src/components/EditProduct';
import CloseIcon from '@mui/icons-material/Close';
import TemplateCategories from '../TemplateCategories';
import TemplateCategoryPopup from './template-category-popup';
import ImportCSV from '../Shops/ImportCSV';
import ImportTemplateCSV from './ImportTemplateCSV';
import InventoryProductsTable from './ViewInventoryProducts';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ManagementServices } from 'src/services/ManagementServices';
import Swal from 'sweetalert2';
import EditIcon from '@mui/icons-material/Edit';
import TemplateEdit from './TemplateEdit';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

function InventoryTable() {
  const [inventory, setInventory] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedQuantity, setQuantity] = useState(null);
  const [selectedPrice, setPrice] = useState(null);
  const [selectedInventoryId, setInventoryId] = useState(null);
  const [templateProduct, setTemplateProduct] = useState<any>([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [selectedtemplateId, setSelectedTemplateId] = useState();
  const [editIndex, setEditIndex] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [openCSV, setOpenCSV] = useState(false);
  const [currentPageComponents, setCurrentPageComponents] = useState<number>(0);
  const [openEditTemplate, setOpenEditTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ifSearchChanged, setIfSearchChanged] = useState(false);
  useEffect(() => {
    getAllTemplateProduct();
  }, [currentSearchTerm, page, rowsPerPage]);

  const getAllTemplateProduct = async () => {
    try {
      const res = await InventoryService.getDefaultInventory(currentSearchTerm, (page + 1).toString(), rowsPerPage);
  
      if (res.success) {
        if(ifSearchChanged ){
          const inventories = res.data.inventories || [];
          setTemplateProduct(inventories);
        }
        else{
          const inventories = res.data.inventories || [];
          setTemplateProduct(prevList => [...prevList, ...inventories]);
        }
     //   const inventories = res.data.inventories || [];
    //    setTemplateProduct(prevList => [...prevList, ...inventories]); 
        setIfSearchChanged(false)
        setLoading(false)
       // setTemplateProduct(res.data.inventories);
        setTotalCount(res.data.totalCount);
      } else {
        toast.error("Failed to fetch inventory");
      }
    } catch (error) {
      console.error("Error fetching inventory:", error);
      toast.error("An error occurred while fetching the inventory");
    }finally {
      
        setIsLoading(false);
    }
    
  };
  

  const handlePageChange = (event: unknown, newPage: number) => {
    
    setLoading(true)
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setCurrentSearchTerm(searchKeyword);
    setIfSearchChanged(true)
    setPage(0);
  };

  const handleSearchClear = () => {
    setIfSearchChanged(true)
    setSearchKeyword('');
    setCurrentSearchTerm('');
    setPage(0);
    //getAllTemplateProduct();
  };

  const theme = useTheme();

  const handleCSVOpen = (inventoryId: any) => {
    setSelectedTemplateId(inventoryId);
    setOpenCSV(true);
  };

  const handleCSVClose = () => {
    setOpenCSV(false);
  };

  const navigateToProductsPage = (inventoryId: any) => {
    if (inventoryId != undefined) {
      setSelectedTemplateId(inventoryId);
      setCurrentPageComponents(1)
    }
  };

  const callBackPage = () => {
    setCurrentPageComponents(0)
  }

const deleteConfirm = (templateInventoryId: any,index:any) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'OK',
    cancelButtonText: 'Close'
  }).then((result) => {
    if (result.isConfirmed) {
      InventoryService.deleteInventoryTemplate(templateInventoryId).then((res) => {
        Swal.fire(
          'Deleted!',
          'Template has been deleted.',
          'success'
        ).then((result) => {
          if (result.isConfirmed) {
           // getAllTemplateProduct();
           const updatedTemplageProduct = [...templateProduct];
                 updatedTemplageProduct.splice(index, 1);
                 setTemplateProduct(updatedTemplageProduct);
          }
        });
      }).catch((error) => {
        Swal.fire(
          'Error!',
          'There was an error deleting your file.',
          'error'
        );
      });
    }
  });
};


const openCloseEditTemplate = (templateId : any,index:any) => {
  setOpenEditTemplate(!openEditTemplate);
  setEditIndex(index);
  setSelectedTemplateId(templateId);
  //getAllTemplateProduct();
}



  return (
    <>
      {
        currentPageComponents == 0 && (
          <Card>
            <CardHeader
              title={
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{
                    fontSize: '2rem',
                    fontWeight: 'bold',

                  }}
                >
                  Template Inventory Details
                </Typography>
              }
              action={
                <Box display="flex" alignItems="center" gap={1}>
                  <TemplateCategoryPopup
                    templateProduct={templateProduct}
                    setTemplateProduct={setTemplateProduct}
                  />
                  <TextField
                    variant="outlined"
                    placeholder="Search Product"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => handleSearch()} color="primary">
                            <SearchIcon />
                          </IconButton>
                          <IconButton onClick={handleSearchClear} color="error">
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Box>
              }
            />
            <Divider />
            {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <CircularProgress />
        </Box>
      ) :(
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell align="center">Index</TableCell> */}
                    <TableCell >Template Name</TableCell>
                    <TableCell >Import CSV</TableCell>
                    <TableCell >View Products</TableCell>
                    <TableCell >Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                {templateProduct.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                  <Typography 
                      variant="h4" 
                      component="h2" 
                      sx={{ 
                        fontSize: '1rem',
                        fontWeight: 'bold',
                      }}
                    >
                    No data to display
                  </Typography>
                  </TableCell>
                </TableRow>
              ) : (

                  templateProduct.map((inventory, index) => (
                      <TableRow hover key={index}>
                        {/* <TableCell align="center">{index + 1 + (page * rowsPerPage)}</TableCell> */}
                        <TableCell >{inventory.templateName}</TableCell>
                        <TableCell >
                          <Tooltip title="Import inventory" arrow>
                            <IconButton
                              onClick={() => {
                                handleCSVOpen(inventory._id);
                              }}
                              sx={{
                                '&:hover': { background: theme.colors.primary.lighter },
                                color: theme.palette.primary.main
                              }}
                              color="inherit"
                              size="small"
                            >
                              <UploadIcon fontSize="small" />
                            </IconButton>
                          </Tooltip></TableCell>
                        <TableCell>
                          <Tooltip title="View All Products" arrow>
                            <IconButton
                              onClick={() => {
                                navigateToProductsPage(inventory._id);
                              }}
                              sx={{
                                '&:hover': { background: theme.colors.primary.lighter },
                                color: theme.palette.primary.main
                              }}
                              color="inherit"
                              size="small"
                            >
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                          </Tooltip></TableCell>
                        <TableCell align="right">
                          <Tooltip title="Edit" arrow>

                            <IconButton
                               onClick={() => openCloseEditTemplate(inventory._id,index)}
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: theme.palette.primary.main
                              }}
                              color="inherit"
                              size="small"
                            >
                              <EditIcon fontSize="small" color="inherit"/>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete" arrow>

                            <IconButton
                               onClick={() => deleteConfirm(inventory._id,index)}
                              sx={{
                                '&:hover': {
                                  background: theme.colors.primary.lighter
                                },
                                color: theme.palette.primary.main
                              }}
                              color="inherit"
                              size="small"
                            >
                              <DeleteTwoToneIcon fontSize="small" color="error"/>
                            </IconButton>
                          </Tooltip>
                        </TableCell>

                      </TableRow>
                    )))}
                </TableBody>
              </Table>
            </TableContainer>)}

            {templateProduct.length === 0 ? (
          
                  <Typography >

                  </Typography>
             
              ) : (
            <Box p={2}  display="flex" justifyContent="center">
       {
        totalCount > templateProduct.length && (
          <Button
          variant="contained"
          color="primary"
          onClick={(e) => handlePageChange(e, page + 1)}
          >
          {loading ? 'Loading...' : 'See More'}
        </Button>
        )
       }
              {/* <TablePagination
                component="div"
                count={totalCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[20, 30, 40, 50]}
              /> */}
            </Box>)}
          </Card>
        )}

      <ImportTemplateCSV open={openCSV} onClose={handleCSVClose} selectedtemplateId={selectedtemplateId} />
      <TemplateEdit 
      // open={openEditTemplate} 
      // onClose={openCloseEditTemplate} 
      // templateId={selectedtemplateId} 
      // get={getAllTemplateProduct}
      open={openEditTemplate}
      onClose={openCloseEditTemplate}
      templateId={selectedtemplateId}
      templateProduct={templateProduct}
      setTemplateProduct={setTemplateProduct}
      index={editIndex}
      />

      {
        currentPageComponents == 1 && (
          <InventoryProductsTable callBackPage={() => callBackPage()} selectedtemplateId={selectedtemplateId} />
        )
      }
    </>
  );
};

export default InventoryTable;
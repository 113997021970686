import { Typography } from '@mui/material';
import TemplateCategoryPopup from './template-category-popup';

function PageHeader({ TemplateCategories, setTemplateCategories }) {

  return (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        Main Inventory
      </Typography>
      <Typography variant="subtitle2">
        Inventories of the Budget App
      </Typography>
      {/* <TemplateCategoryPopup
        TemplateCategories={TemplateCategories}
        setTemplateCategories={setTemplateCategories}
      /> */}
    </>
  );
}

export default PageHeader;
import { Box, Button, Container, Dialog, DialogContent, DialogTitle, IconButton, Link, TextField, Typography, styled } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/services/InventoryService';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}


function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
function EditInventoryTemplateProduct({
  open,
  onClose,
  productName,
  quantity,
  price,
  shopId,
  inventoryId,
  templateProduct, 
  setTemplateProduct,
   index
  
}) {
  const validationSchema = yup.object({
    TemplateName: yup.string().typeError('Not a String'),
    SKUNumber: yup.string().typeError('Not a String')
  });

  const updateInventory = (value) => {
    const id = shopId;
    const data = {
      inventoryId: inventoryId,
      productName: value.productName || productName,
      quantity: value.quantity || quantity,
      price: value.price || price
    };
    InventoryService.updateProductQP(data, id).then((res) => {
      if (res.success) {
      const updatedTemplageProduct = [...templateProduct]; 
      updatedTemplageProduct[index] = { ...updatedTemplageProduct[index], productName: value.productName || productName,
        quantity: value.quantity || quantity,
        price: value.price || price };
 
      setTemplateProduct(updatedTemplageProduct);
        onClose();
        Swal.fire('Product Updated', 'Successfully', 'success');
       
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00'
        });
      }
    });
  };

  const updateTemplateInventory = (value) => {
    const id = inventoryId;
    const data = {
      productName: value.productName || productName,
      quantity: value.quantity || quantity,
      price: value.price || price
    };

    InventoryService.updateTemplateProductQP(data, id).then((res) => {
      if (res.success) {
        onClose();
        const updatedTemplageProduct = [...templateProduct]; 
        updatedTemplageProduct[index] = { ...updatedTemplageProduct[index], productName: value.productName || productName,
          quantity: value.quantity || quantity,
          price: value.price || price };
   
        setTemplateProduct(updatedTemplageProduct);
      
        Swal.fire('Product Updated', 'Successfully', 'success');
     
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00'
        });
      }
    });
  };

  const formikupdate = useFormik({
    initialValues: {
      productName: productName,
      quantity: quantity,
      price: price
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (shopId === 'template') {
        updateTemplateInventory(values);
      } else {
        updateInventory(values);
      }
      formikupdate.resetForm();
    }
  });

  return (
    <Dialog
      // onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        <Typography variant="h4" fontWeight="bold">
          Edit {productName} Details
        </Typography>
      </BootstrapDialogTitle> */}
      <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
            Edit {productName} Details
        </Typography>

      <DialogContent dividers>
        <form onSubmit={formikupdate.handleSubmit}>
        <Typography>
            Product Name
        </Typography>
        <TextField
            required
            id="productName"
            name="productName"
            defaultValue={productName}
            onChange={formikupdate.handleChange}
            fullWidth
          />
          <div style={{ height: 20 }}></div>
          <Typography>
                Quantity
          </Typography>
          <TextField
            required
            id="quantity"
            name="quantity"
            defaultValue={quantity}
            onChange={formikupdate.handleChange}
            fullWidth
          />
          <div style={{ height: 20 }}></div>
          <Typography>
                Price
          </Typography>
          <TextField
            required
            id="price"
            name="price"
            defaultValue={price}
            onChange={formikupdate.handleChange}
            fullWidth
          />
          <div style={{ height: 20 }}></div>

          <Button color="primary" variant="contained" type="submit" fullWidth>
            Update
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default EditInventoryTemplateProduct;

import React, { useEffect, useState } from 'react';
import {DialogTitle,IconButton, Modal, Box, Typography, TextField, Button, DialogContent, Dialog, Select, MenuItem, SelectChangeEvent, InputAdornment } from '@mui/material';
import { InventoryDetails } from "src/models/Inventory";
import { InventoryService } from 'src/services/InventoryService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { InventoryCategories } from 'src/models/InventoryCategory';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { ManagementServices } from 'src/services/ManagementServices';
import CloseIcon from '@mui/icons-material/Close';
import { VisibilityOff, Visibility } from '@mui/icons-material';

export interface ProductProps {
    open: boolean;
    onClose: () => void;
}
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
const ChangePassword: React.FC<ProductProps> = ({ open, onClose }) => {

    const [newPassword, setNewPassword] = useState<String>("");
    const [oldPassword, setOldPassword] = useState<String>("");
    const [confirmPassword, setconfirmPassword] = useState<String>("");
    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [showPasswordCon, setShowPasswordCon] = useState(false);




    const handleSubmit = (values: any) => {

        const data: any = {

            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword

        };

        ManagementServices.changePassword(data).then(res => {

            if (res.success) {
                Swal.fire('Password Changed Successfully');
                onClose()
            } else {
                onClose()
                Swal.fire({
                    icon: 'error',
                    title: res.error,
                    confirmButtonColor: '#FD7F00'
                });

            }
        })
    };

    const handleClickShowPasswordOld = () => {
      setShowPasswordOld(!showPasswordOld);
    };

    const handleClickShowPasswordNew = () => {
      setShowPasswordNew(!showPasswordNew);
    };

    const handleClickShowPasswordCon = () => {
      setShowPasswordCon(!showPasswordCon);
    };

    return (
      <div>
        <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 4,
                minHeight: '400px'
              }}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={onClose}
              >
                Change Password
              </BootstrapDialogTitle>
              <TextField
                fullWidth
                label="old Password"
                margin="normal"
                variant="outlined"
                name="OldPassword"
                value={oldPassword}
                type={showPasswordOld ? 'text' : 'password'}
                onChange={(e) => setOldPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordOld}
                        edge="end"
                      >
                        {showPasswordOld ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                label="New Password"
                margin="normal"
                variant="outlined"
                name="ConfirmPassword"
                value={newPassword}
                type={showPasswordNew ? 'text' : 'password'}
                onChange={(e) => setNewPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordNew}
                        edge="end"
                      >
                        {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                label="Confirm Password"
                margin="normal"
                variant="outlined"
                name="ConfirmPassword"
                value={confirmPassword}
                type={showPasswordCon ? 'text' : 'password'}
                onChange={(e) => setconfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordCon}
                        edge="end"
                      >
                        {showPasswordCon ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mt: 2 }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    );
};

export default ChangePassword;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { TemplateCategoryDetails } from 'src/models/TemplateCategory';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SketchPicker } from 'react-color';
import { InventoryService } from 'src/services/InventoryService';
import Swal from 'sweetalert2';
import { useState } from 'react';
import FormData from 'form-data';
import { ArrowDropDown } from '@mui/icons-material';
import Papa from 'papaparse';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton 
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function TemplateCategoryPopup({templateProduct, setTemplateProduct}) {
  const [open, setOpen] = useState(false);

  
    
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };




  const formik = useFormik({
    initialValues: {
      templateName: '',

    },
    validationSchema: yup.object({
      templateName: yup.string().required('Template Name is required'),

    }),
    onSubmit: (values, { resetForm }) => {
      const data = {
        templateName: values.templateName
      };
      InventoryService.createInventory(data).then((res) => {
       
        if (res.success) {

          setOpen(false);
          const updatedTemplateProduct = [res?.data,...templateProduct];

    // Update the state with the new array
        setTemplateProduct(updatedTemplateProduct);
          Swal.fire('Inventory created', 'Successfully', 'success').then((result) => {
            if (result.isConfirmed) {
              resetForm();
            //  get(); // Refresh the page after OK button
            }
          });
        } else {
          setOpen(false);
          Swal.fire({
            icon: 'error',
            title: res.error,
            confirmButtonColor: '#FD7F00',
          });
        }
      });
    }
  });


  return (
    <div>
      <Button variant="outlined" onClick={() => handleClickOpen()}>
        Create Template Inventory
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth maxWidth="md">
        {/* <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}>
          <Typography variant="h4" fontWeight="bold">
            Create a New Inventory Template
          </Typography>

          
        </BootstrapDialogTitle> */}
        <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
        Create a New Inventory Template
        </Typography>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>

            <TextField
              fullWidth
              id="templateName"
              name="templateName"
              label="Template Name"
              value={formik.values.templateName}
              onChange={formik.handleChange}
              error={
                formik.touched.templateName && Boolean(formik.errors.templateName)}
              helperText={formik.touched.templateName && formik.errors.templateName}
            />

            <div style={{ height: 10 }}></div>

            <Button color="primary" variant="contained" type="submit" fullWidth>
              Create
            </Button>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default TemplateCategoryPopup;

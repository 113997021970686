import { Autocomplete, Box, Button, Container, Dialog, DialogContent, DialogTitle, IconButton, Link, TextField, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/services/InventoryService';



export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  setInvoiceList:any;
  invoiceList: any;
  index:any
}


function CustomerInvoiceEdit({
  open,
  onClose,
  invoiceId,
  setInvoiceList,
  invoiceList,
  index
}) {

  const validationSchema = yup.object({
    createdAt: yup.string().typeError('Not a String').required('Required'),
  });

  const formikupdate = useFormik({
    initialValues: {
      createdAt: invoiceList[index]?.createdAt || '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("values", values)
      updateInvoice(values);
      // formikupdate.resetForm();
    }
  });

  const updateInvoice = (values: any) => {
    onClose();
    const data = {
      createdAt: values.createdAt,
    };
    InventoryService.updateInvoiceByUser(data, invoiceId).then((res) => {
      if (res.success) {
         const updatedCustomerList = [...invoiceList];
         updatedCustomerList[index] = {
           ...updatedCustomerList[index],
           createdAt: values.createdAt,
         };

         setInvoiceList(updatedCustomerList);
        Swal.fire('Shop Updated', 'Successfully', 'success');
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00'
        });
      }
    });
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
  
      <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
        Edit Invoice Details
      </Typography>
      <DialogContent dividers>
        {invoiceList ? (
          <form onSubmit={formikupdate.handleSubmit}>
            <Typography>Created Date</Typography>
            <TextField
              id="createdAt"
              name="createdAt"
              value={formikupdate.values.createdAt}
              onChange={formikupdate.handleChange}
              fullWidth
            />
            <div style={{ height: 20 }}></div>

            <Button color="primary" variant="contained" type="submit" fullWidth>
              Update
            </Button>
          </form>
        ) : (
          <Typography variant="body1">Loading...</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default CustomerInvoiceEdit;

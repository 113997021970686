import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthService } from 'src/services/AuthService';
import { ShopsDetails } from 'src/models/Shops';
import moment from 'moment';

interface UserDetailsProps {
  open: boolean;
  onClose: () => void;
  shopId: string;
 
}

function UserDetails({ open, onClose, shopId }: UserDetailsProps) {
  const [userDetails, setUserDetails] = useState<ShopsDetails>();
  const [isLoading, setIsLoading] = useState(true);
  const [categoryCount, setCategoryCount] = useState<number>(0);
  const [customerCount, setCustomerCount] = useState<number>(0);
  const [invoiceCount, setInvoiceCount] = useState<number>(0);
  const [selectedShopEmail, setSelectedShopEmail] = useState();

  useEffect(() => {
    if (open) {
      getUser(shopId);
    }
  }, [shopId, open]);

  const getUser = async (shopId: string) => {
    try {
      setIsLoading(true);
      const res = await AuthService.getShopByShopId(shopId);
      if (res.success) {
        setUserDetails(res.data.shopDetails.shopDetails);
        setSelectedShopEmail(res.data.shopDetails.userEmail);
        setCategoryCount(res.data.categoryCount);
        setCustomerCount(res.data.customerCount);
        setInvoiceCount(res.data.invoiceCount);
      }
    } catch (error) {
      console.error("Error fetching shop details:", error);
      toast.error('An error occurred while fetching shop details');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h4" fontWeight="bold">
          User Details
        </Typography>
      </DialogTitle>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <CircularProgress />
        </Box>
      ) : (
       
        <DialogContent dividers>
          <Grid>
            <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                          <Typography variant="h5">
                            User Name: 
                          </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                          <Typography variant="body1">
                          {userDetails?.ownerName}
                          </Typography>
                      </Grid>
              </Grid>

              <div style={{ height: 15 }}></div>
                <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                        <Typography variant="h5">
                        Shop Registration Number:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                        {userDetails?.shopRegisterNumber}
                        </Typography>
                    </Grid>
              </Grid>

              <div style={{ height: 15 }}></div>
              <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5">
                        User Email:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                        {selectedShopEmail}
                        </Typography>
                    </Grid>
              </Grid>

              <div style={{ height: 15 }}></div>
              <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h5">
                        Shop Address:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                        {userDetails?.shopAddress}
                        </Typography>
                    </Grid>
              </Grid>

              <div style={{ height: 15 }}></div>
              <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5">
                        Currency Type:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1">
                        {userDetails?.currency?.currencyName}
                        </Typography>
                    </Grid>
              </Grid>
{/* 
              <div style={{ height: 15 }}></div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5">
                    Status:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                    {status}
                    </Typography>
                </Grid>
              </Grid> */}

              <div style={{ height: 15 }}></div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5">
                    Created Date:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                    {moment(userDetails?.createdAt).format('MMM Do YYYY')}
                    </Typography>
                </Grid>
              </Grid>

              <div style={{ height: 15 }}></div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5">
                    Customer Count:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                    {customerCount}
                    </Typography>
                </Grid>
              </Grid>

              <div style={{ height: 15 }}></div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5">
                    Invoice Count:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                    {invoiceCount}
                    </Typography>
                </Grid>
              </Grid>

              <div style={{ height: 15 }}></div>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5">
                    Category Count:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                    {categoryCount}
                    </Typography>
                </Grid>
              </Grid>





            </Grid>
            
            
           
        </DialogContent>
      )}
    </Dialog>
  );
}

export default UserDetails;

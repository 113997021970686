import React from 'react';
import { Box, Typography } from '@mui/material';

interface PaymentStatusBoxProps {
  status: string;
}

const PaymentStatusBox: React.FC<PaymentStatusBoxProps> = ({ status }) => {
  const getColorForStatus = (status: string) => {
    switch (status.toUpperCase()) {
      case "YEARLY":
        return '#fff8e1';
      case "MONTHLY":
        return '#d191ff';
      case 'PAID':
        return '#e6f4ea';  // Light green
      case 'PENDING':
      case 'TRIAL':
        return '#e6f4ea';  // Light yellow
      default:
        return '#ffebee';  // Light red
    }
  };

  const getTextColorForStatus = (status: string) => {
    switch (status.toUpperCase()) {
      case 'YEARLY':
        return '#f9a825';
      case 'MONTHLY':
        return '#552478';
      case 'PAID':
        return '#1e8e3e'; // Dark green
      case 'PENDING':
      case 'TRIAL':
        return '#1e8e3e'; // Dark yellow
      default:
        return '#d32f2f'; // Dark red
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: getColorForStatus(status),
        color: getTextColorForStatus(status),
        padding: '2px 2px',
        borderRadius: '4px',
        display: 'inline-block',
        width: '80px',
        textAlign: 'center',
      }}
    >
      <Typography variant="body2" fontWeight="bold" sx={{ fontSize: '0.7rem' }}>
        {status.toUpperCase()}
      </Typography>
    </Box>
  );
};

export default PaymentStatusBox;
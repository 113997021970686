import { Autocomplete, Box, Button, Container, Dialog, DialogContent, DialogTitle, IconButton, Link, TextField, Typography, styled, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/services/InventoryService';
import { AppResponse } from 'src/models/Response';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import { AuthService } from 'src/services/AuthService';
import { ShopsDetails } from 'src/models/Shops';
import moment from 'moment';
import { ManagementServices } from 'src/services/ManagementServices';


export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}


function DeletePopUp({ open, onClose, shopId, index, get}) {

  // const [allShops, setAllShops] = useState<any[]>([]);
  

  useEffect(() => {
    if(open){ 
      // setShowDialog(true);
    }
   
  }, [shopId]);

  const handleDeleteOnlyShop = (deleteOption: string) => {
    onClose(); 
    get(deleteOption); 
  };
  
 
  
  return (
    
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      
        <>
          <DialogTitle>
            <Typography variant="h4" fontWeight="bold">
              Select Delete Option
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
             display="flex" 
             flexDirection="column" 
             alignItems="center" 
             justifyContent="center"
             mt={3}
             width="100%"
             >
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleDeleteOnlyShop('OnlyShop')}
                sx={{ width: '50%', mb: 2 }}
              >
                Delete shop
              </Button>
            </Box>
            <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center"
            mt={3}
            width="100%"
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={() => handleDeleteOnlyShop('shopWithUser')}
                sx={{ width: '50%', mb: 2 }}
              >
                Delete user
              </Button>
            </Box>
            <div style={{ height: 15 }}></div>
          </DialogContent>
        </>
     
    </Dialog>
  );
}

export default DeletePopUp;

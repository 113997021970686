import {
    Box,
    Card, Grid,
    Typography,
    Container,
    Divider,
    Button,
    FormControl,
    OutlinedInput,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Alert,
    Input,
    IconButton,
    InputAdornment
  } from '@mui/material';
  import { Helmet } from 'react-helmet-async';
  import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
  import * as Yup from 'yup';
  import { useFormik } from 'formik';
  import { styled } from '@mui/material/styles';
  import { useEffect, useState, useRef } from 'react';
  import { AuthService, UserLoginData } from 'src/services/AuthService';
  import { Label } from '@mui/icons-material';
  import { useNavigate } from 'react-router-dom';
  import { Visibility, VisibilityOff } from '@mui/icons-material';
  
  const MainContent = styled(Box)(
    ({ theme }) => `
        height: 100%;
        display: flex;
        flex: 1;
        overflow: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `
  );

  const GradientButton = styled(Button)({
    background: 'linear-gradient(to right,#F963AB, #7555FF)', // Example gradient colors
    border: 0,
    borderRadius: 10,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    '&:hover': {
      background: 'linear-gradient(to right, #7555FF, #F963AB)', // Gradient on hover
    },
  });
  
  const OutlinedInputWrapper = styled(OutlinedInput)(
    ({ theme }) => `
        background-color: ${theme.colors.alpha.white[100]};
    `
  );
  
  const ButtonSearch = styled(Button)(
    ({ theme }) => `
        margin-right: -${theme.spacing(1)};
    `
  );
  
  
  
  function PublicInvoiceView() {
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState<string | undefined>();
    const [invoiveItems, setinvoiveItems] = useState([]);
    const invoiceNumberRef = useRef(null);
    let navigate = useNavigate();
    

    useEffect(() => {
        const url = window.location.href;
        const invoiceNumber = url.split('/').pop();
        invoiceNumberRef.current = invoiceNumber;
    
      }, []); 

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  
    const validation = useFormik({
      enableReinitialize: true,
  
      initialValues: {
        phoneNumber: '',
      },
      validationSchema: Yup.object({
        phoneNumber: Yup.string()
          .required('Please Enter Your Phone Number')
      }),
  
  
      onSubmit: (number) => {
        const invoiceData = {
        phoneNumber:number,
        };
  
        sendInvoice(invoiceData);
      }
    });
  
    const sendInvoice = async (values: any) => {

    //   try {
    //     const response = await AuthService.userLogin(values);
    //     setIsLoading(false);
    //     if (response.success && response.data.role === 'SUPER_ADMIN') {
    //       navigate('/dashboards');
    //     } else if (!response.success) {
    //       if (typeof response.error == 'string') {
    //         setError(response.error);
    //       } else {
    //         setError('Connection Error!');
    //       }
    //     } else {
    //       setError('Not Authorized');
    //     }
    //   } catch (error) {
    //     setIsLoading(false);
    //     setError('Connection Error!');
    //   }
    };
    
    return (
      <>
        <Helmet>
          <title>Invoice</title>
        </Helmet>
        <MainContent>
          <Container maxWidth="md">
          <Container maxWidth="sm">
              <Card sx={{ textAlign: 'center', mt: 3, p: 4 }}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                <Box textAlign="center">
                <img alt="404" height={180} src="/static/images/status/app_logo.svg" />
                <Typography variant="h2" sx={{ my: 2 }}>
                    Fan Budget Invoice
                </Typography>
                <Typography
                    variant="h4"
                    color="text.secondary"
                    fontWeight="normal"
                    sx={{ mb: 4 }}
                >
                    Invoice
                </Typography>
                </Box>

                <Divider />
              
                <TableContainer>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>product Id</TableCell>
                        <TableCell>Product Name</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Amount</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {invoiveItems.map((inventory, index) => {
                        return (
                        <TableRow hover key={inventory._id}>
                            <TableCell>
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                            >
                                {index + 1}
                            </Typography>
                            </TableCell>


                            <TableCell>
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                            >
                                {inventory.templateName}
                            </Typography>
                            </TableCell>

                            <TableCell>
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                            >
                            {inventory.quantity}
                            </Typography>
                            </TableCell>

                            <TableCell>
                            <Typography
                                variant="body1"
                                fontWeight="bold"
                                color="text.primary"
                                gutterBottom
                                noWrap
                            >
                            {inventory.price}
                            </Typography>
                            </TableCell>

                        </TableRow>
                        );
                    })}
                    </TableBody>
                </Table>
                </TableContainer>
                <Divider />
                  {error ? <Alert color="error">{error}</Alert> : null}
                  <FormControl variant="outlined" fullWidth>
                    <div>
                        <p className="form-label">{'Phone Number'}</p>
                        <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        value={validation.values.phoneNumber}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        error={validation.touched.phoneNumber && Boolean(validation.errors.phoneNumber)}
                        helperText={validation.touched.phoneNumber && validation.errors.phoneNumber}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                            <GradientButton type="submit" variant="outlined" fullWidth>
                               Save Invoice
                            </GradientButton>
                            </InputAdornment>
                            ),
                        }}
                        />
                    </div>
                    <Divider sx={{ my: 4 }} />
                    </FormControl>
                </form>
              </Card>
            </Container>
          </Container>
        </MainContent>
      </>
    );
  }
  
  export default PublicInvoiceView;
  
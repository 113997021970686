import { Avatar, Box, Button, Card, CardHeader, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography, styled, useTheme } from "@mui/material";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { CategoryDetails } from "src/models/Category";
import { ManagementServices } from "src/services/ManagementServices";
import BulkActions from "../../Transactions/BulkActions";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CopyAllTwoTone from '@mui/icons-material/CopyAllTwoTone';
import Swal from "sweetalert2";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import { activeStatus } from "src/models/ChapterGroup";
import 'react-toastify/dist/ReactToastify.css';
import "../../ToastStyle.css";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ): null}
        </DialogTitle>
    );
}

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
        backgound: ${theme.colors.primary.lighter};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(4)};
        height: ${theme.spacing(4)};
    `
);

interface Filters {
    status?: any;
}

const applyFilters = (
    AllChapterGroups: CategoryDetails[],
    filters: Filters
): any[] => {
    return AllChapterGroups.filter((category) => {
        let matches = true;

        let statusString;
        if(category.isActive === true) {
            statusString = 'ACTIVE';
            if(filters.status && statusString !== filters.status) {
                matches = false;
            }
        } else {
            statusString = 'INACTIVE';
            if(filters.status && statusString !== filters.status) {
                matches = false;
            }
        }

        return matches;
    });
};

const applyPagination = (
    allGroups: CategoryDetails[],
    page: number,
    limit: number
): any[] => {
    return allGroups.slice(page * limit, page * limit + limit);
};

interface Props {
    AllUserCategories: CategoryDetails[],
    setAllUserCategories: React.Dispatch<React.SetStateAction<CategoryDetails[]>>;
}

const AllUserCategoryTable: FC<Props> = ({
    AllUserCategories,
    setAllUserCategories
}) => {

    const [selectedAllGroups] = useState<string[]>([]);
    const selectedBulkActions = selectedAllGroups.length > 0;
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(5);
    const [filters, setFilters] = useState<Filters>({
        status: null
    })

    const LIMIT_P_C = 10;
    const [isOffset_p_c, setOffset_p_c] = useState<number>(0);
    const [seeMore_p_c, setSeeMore_p_c] = useState(false);

    const [CategoryId, setCategoryId] = useState();
    const [Status, setStatus] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getUserCategories();
    }, []);

    const getUserCategories = () => {
        ManagementServices.getAllUserCategories(LIMIT_P_C, isOffset_p_c).then((res) => {
            if(res.success) {
                setAllUserCategories(res.data);
                setSeeMore_p_c(res.data.length > 0 && res.data.length == LIMIT_P_C);
            } else {
                setSeeMore_p_c(false);
            }
        })
    }

    const handleClickOpen = (categoryid, status) => {
        setCategoryId(categoryid);
        setStatus(status);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCopy = async (categoryId) => {
        await navigator.clipboard.writeText(categoryId);
        toast("Copied to clipboard");
    };

    const updateUserCategory = () => {
        setOpen(false);
        const data = {
            categoryId: CategoryId,
            isActive: Status
        };

        ManagementServices.updateCategoryStatus(data).then((res) => {
            if(res.success) {
                getUserCategories()
                Swal.fire('User Category Updated');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: res.error,
                    confirmButtonColor: '#FD7F00'
                });
            }
        });
    };

    const deleteUserCategory = (categoryId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if(result.isConfirmed) {
                ManagementServices.deleteUserCategory(categoryId).then((res) => {
                    if(res.success) {
                        setAllUserCategories(
                            AllUserCategories.filter((CategoryDetails) => CategoryDetails._id !== categoryId)
                        );
                        Swal.fire('Category Removed');
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: res.error,
                            confirmButtonColor: '#FD7F00'
                        });
                    }
                });
            }
        });
    };

    const statusOptions = [
        {
          id: 'ALL',
          name: 'ALL'
        },
        {
          id: 'ACTIVE',
          name: 'ACTIVE'
        },
        {
          id: 'INACTIVE',
          name: 'INACTIVE'
        }
    ];

    const statusupdateOptions = [
        {
          id: "true",
          name: activeStatus.ACTIVE
        },
        {
          id: "false",
          name: activeStatus.INACTIVE
        }
    ];

    const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
        let value = null;

        if(e.target.value !== 'ALL') {
            value = e.target.value;
        }

        setPage(0);
        setFilters((prevFilters) => ({
            ...prevFilters,
            status: value
        }));
    };

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };
    
    const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    const filteredAllGroups = applyFilters(AllUserCategories, filters);
    const paginatedAllGroups = applyPagination(filteredAllGroups, page, limit);
    const theme = useTheme();

    const handleUpdateStatus = (event) => {
        setStatus(event.target.value);
    };

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Update Category Status
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        style={{width: `300px`}}
                        labelId="demo-simple-select-label"
                        id="filled-select-currency"
                        variant="filled"
                        value={Status}
                        onChange={handleUpdateStatus}
                        label="Status"
                        autoWidth
                    >
                        {statusupdateOptions.map((statusupdateOptions) => (
                            <MenuItem style={{ width: `300px` }} key={statusupdateOptions.id} value={statusupdateOptions.id}>
                                {statusupdateOptions.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <div style={{ height: 10 }}></div>
                    <Button color="primary" variant="contained" type="submit" onClick={() => updateUserCategory()}>
                        Update
                    </Button>
                </DialogContent>
            </BootstrapDialog>
            <Card>
                {selectedBulkActions && (
                    <Box flex={1} p={2}>
                        <BulkActions />
                    </Box>
                )}
                {!selectedBulkActions && (
                    <CardHeader 
                        action={
                            <Box width={150}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={filters.status || 'ALL'}
                                        onChange={handleStatusChange}
                                        label="Status"
                                        autoWidth
                                    >
                                        {statusOptions.map((statusOptions) => (
                                            <MenuItem key={statusOptions.id} value={statusOptions.id}>
                                                {statusOptions.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                        title="User Category"
                    />
                )}
                <Divider/>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width={5}>Icon</TableCell>
                                <TableCell>Category Name</TableCell>
                                <TableCell>Chapter group</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Create at</TableCell>
                                <TableCell align='right'>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedAllGroups.map((category) => {
                                return (
                                    <TableRow  sx={{
                                        '&:hover': {
                                          background: theme.colors.primary.lighter,
                                          //cursor: 'pointer',
                                        },
                                        color: theme.palette.warning.main
                                    }} key={category._id}>
                                        <TableCell>
                                            <AvatarPrimary style={{ backgroundColor: category.color }}>
                                                {category.iconValue.icon && <img src={`data:image/svg+xml;base64,${category.iconValue.icon}`} />}
                                            </AvatarPrimary>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                                {category.category}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                                {category.chapterGroup.chapterGroup}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                                {category.isActive === true ? 'ACTIVE' : 'INACTIVE'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" fontWeight="bold" color="text.primary" gutterBottom noWrap>
                                                {moment(category.createdAt && category.createdAt).format("MMM Do YY")}  
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Tooltip title="Copy Id" arrow>
                                                <IconButton
                                                    onClick={() => handleCopy(category._id)}
                                                    sx={{
                                                        '&:hover': {
                                                          background: theme.colors.primary.lighter
                                                        },
                                                        color: theme.palette.warning.main
                                                    }}
                                                    color="inherit"
                                                    size="small"
                                                >
                                                    <CopyAllTwoTone fontSize="small"/>
                                                    <ToastContainer
                                                        position="bottom-right"
                                                        autoClose={1000}
                                                        hideProgressBar
                                                        newestOnTop={false}
                                                        closeOnClick={false}
                                                        rtl={false}
                                                        pauseOnFocusLoss={false}
                                                        draggable={false}
                                                        pauseOnHover={false}
                                                        theme="dark"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit category" arrow>
                                                <IconButton
                                                    onClick={() => handleClickOpen(category._id, category.isActive)}
                                                    sx={{
                                                        '&:hover': {background: theme.colors.primary.lighter},
                                                        color: theme.palette.primary.main
                                                    }}
                                                    color="inherit"
                                                    size="small"
                                                >
                                                    <EditTwoToneIcon fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                            {/* <Tooltip title="Delete Order" arrow>
                                                <IconButton
                                                    onClick={() => deleteUserCategory(category._id)}
                                                    sx={{
                                                        '&:hover': { background: theme.colors.error.lighter },
                                                        color: theme.palette.error.main
                                                    }}
                                                    color="inherit"
                                                    size="small"
                                                >
                                                    <DeleteTwoToneIcon fontSize="small"/>
                                                </IconButton>
                                            </Tooltip> */}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box p={2}>
                    <TablePagination
                        component="div"
                        count={filteredAllGroups.length}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 25, 30]}
                    />
                </Box>
            </Card>
        </>
    )
};

export default AllUserCategoryTable;
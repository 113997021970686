import { Box, Button, Container, Dialog, DialogContent, DialogTitle, IconButton, Link, TextField, Typography, styled } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/services/InventoryService';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}


function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
function EditProduct({
  open,
  onClose,
  productName,
  quantity,
  price,
  shopId,
  inventoryId,
  setInventory,
  inventory,
  index
  // updateInventoryList,
  // get
}) {
  const validationSchema = yup.object({
    TemplateName: yup.string().typeError('Not a String'),
    SKUNumber: yup.string().typeError('Not a String')
  });

  const updateInventory = (value) => {
    const id = shopId;
    const data = {
      inventoryId: inventoryId,
      productName: value.productName || productName,
      quantity: value.quantity || quantity,
      price: value.price || price
    };
    InventoryService.updateProductQP(data, id).then((res) => {
      if (res.success) {
        // updateInventoryList(
        //   value.productName || productName,
        //   value.quantity || quantity,
        //   value.price || price
        // );
      
      
 const updatedInventoryList = [...inventory];
 updatedInventoryList[index] = {
   ...updatedInventoryList[index],
   productName: value.productName || productName,
   quantity: value.quantity || quantity,
   price: value.price || price
 };

 setInventory(updatedInventoryList);
        onClose();
        Swal.fire('Product Updated', 'Successfully', 'success');
      //  get();
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00'
        });
      }
    });
  };

  const updateTemplateInventory = (value) => {
    const id = inventoryId;
    const data = {
      productName: value.productName || productName,
      quantity: value.quantity || quantity,
      price: value.price || price
    };
    console.log(data);

    InventoryService.updateTemplateProductQP(data, id).then((res) => {
      if (res.success) {
        onClose();
        // updateInventoryList(
        //   value.productName || productName,
        //   value.quantity || quantity,
        //   value.price || price
        // );
         const updatedInventoryList = [...inventory];
         updatedInventoryList[index] = {
           ...updatedInventoryList[index],
           productName: value.productName || productName,
           quantity: value.quantity || quantity,
           price: value.price || price
         };

         setInventory(updatedInventoryList);
        Swal.fire('Product Updated', 'Successfully', 'success');
      //  get();
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00'
        });
      }
    });
  };

  const formikupdate = useFormik({
    initialValues: {
      productName: productName,
      quantity: quantity,
      price: price
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (shopId === 'template') {
        updateTemplateInventory(values);
      } else {
        updateInventory(values);
      }
      formikupdate.resetForm();
    }
  });

  return (
    <Dialog
      // onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        <Typography variant="h4" fontWeight="bold">
          Edit {productName} Details
        </Typography>
      </BootstrapDialogTitle>

      <DialogContent dividers>
        <form onSubmit={formikupdate.handleSubmit}>
          <TextField
            required
            id="productName"
            name="productName"
            label="Product Name"
            defaultValue={productName}
            onChange={formikupdate.handleChange}
            fullWidth
          />
          <div style={{ height: 20 }}></div>
          <TextField
            required
            id="quantity"
            name="quantity"
            label="Quantity"
            defaultValue={quantity}
            onChange={formikupdate.handleChange}
            fullWidth
          />
          <div style={{ height: 20 }}></div>
          <TextField
            required
            id="price"
            name="price"
            label="Price"
            defaultValue={price}
            onChange={formikupdate.handleChange}
            fullWidth
          />
          <div style={{ height: 20 }}></div>

          <Button color="primary" variant="contained" type="submit" fullWidth>
            Update
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default EditProduct;

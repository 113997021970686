import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { ManagementServices } from 'src/services/ManagementServices';
import {
  ClassicEditor,
  Context,
  Essentials,
  Paragraph,
  ContextWatchdog,
  List,
  IndentBlock,
  Undo,
  Heading,
  FontFamily,
  FontSize,
  FontColor,
  FontBackgroundColor,
  Bold,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Code,
  Link,
  BlockQuote,
  CodeBlock,
  Alignment,
  TodoList,
  Indent,
  ImageUpload,
  Image,
  ImageBlock,
  ImageBlockEditing,
  ImageConfig,
  ImageCaptionUtils,
  ImageInline,
  LinkImage,
  ImageInsertUI,
  Base64UploadAdapter,
  SimpleUploadAdapter,
  EasyImage,
} from "ckeditor5-custom-build/build/ckeditor";

import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function ShopEmail({ open, onClose, allShops, setAllShops }) {
  const [loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    ShopEmailSubject: yup
    .string()
    .typeError('Not a String')
    .required('Required'),
    ShopEmailBody: yup.string().typeError('Not a String').required('Required')
  });

  const formik = useFormik({
    initialValues: {
      ShopEmailSubject: '',
      ShopEmailBody: ''
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateShop(values);
      formik.resetForm();
    }
  });

  const updateShop = (values) => {
    onClose();
    const data = {
      shopEmailSubject: values.ShopEmailSubject,
      shopEmailBody: values.ShopEmailBody,
      emails: allShops
      .filter((shop) => shop?.activeStatusMessage === true)
      .map((shop) => shop?.userEmail)
    };
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to send this email!',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(data)
        ManagementServices.sendEmailsByAdmin(data).then((res) => {
          if (res.success) {
            const updatedShops = allShops.map((shop) => ({
              ...shop,
              activeStatusMessage: false
            }));
            setAllShops(updatedShops);
            Swal.fire('Message Sent', 'Successfully', 'success');
          } else {
            Swal.fire({
              icon: 'error',
              title: res.error,
              confirmButtonColor: '#FD7F00'
            });
          }
        });
      } else {
        const updatedShops = allShops.map((shop) => ({
          ...shop,
          activeStatusMessage: false
        }));
        setAllShops(updatedShops);
      }
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
        Shop Email
      </Typography>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>
            <Typography>Email Subject</Typography>
            <TextField
              required
              id="ShopEmailSubject"
              name="ShopEmailSubject"
              value={formik.values.ShopEmailSubject}
              onChange={formik.handleChange}
              fullWidth
            />
            <div style={{ height: 20 }}></div>
            <Typography>Email Body</Typography>
            <CKEditorContext
              context={Context}
              contextWatchdog={ContextWatchdog}
            >
              <CKEditor
                editor={ClassicEditor}
                data={formik.values.ShopEmailBody}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  formik.setFieldValue('ShopEmailBody', data);
                }}
                config={{
                  plugins: [
                    Essentials,
                    Paragraph,
                    Heading,
                    List,
                    Bold,
                    Italic,
                    Indent,
                    IndentBlock,
                    BlockQuote,
                    Link,
                    Image,
                    ImageBlock,
                    ImageBlockEditing,
                    ImageCaptionUtils,
                    ImageInline,
                    LinkImage,
                    ImageInsertUI,
                    ImageUpload,
                    Base64UploadAdapter,
                    FontFamily,
                    FontSize,
                    FontColor,
                    FontBackgroundColor,
                    TodoList,
                    Strikethrough,
                    Subscript,
                    Superscript,
                    Code,
                    Alignment
                  ],
                  // toolbar: [
                  //   'heading',
                  //   'bold',
                  //   'italic',
                  //   'strikethrough',
                  //   'subscript',
                  //   'superscript',
                  //   'code',
                  //   'fontfamily',
                  //   'fontsize',
                  //   'fontColor',
                  //   'fontBackgroundColor',
                  //   'numberedList',
                  //   'bulletedList',
                  //   'todoList',
                  //   'outdent',
                  //   'indent',
                  //   'blockquote',
                  //   'link',
                  //   'alignment'

                  //   // 'imageUpload'
                  // ]
                  toolbar: {
                    items: [
                      'undo',
                      'redo',
                      '|',
                      'heading',
                      '|',
                      'fontfamily',
                      'fontsize',
                      'fontColor',
                      'fontBackgroundColor',
                      '|',
                      'bold',
                      'italic',
                      'strikethrough',
                      'subscript',
                      'superscript',
                      'code',
                      '|',
                      'link',
                      // 'imageUpload'
                      'blockQuote',
                      '|',
                      'alignment',
                      '|',
                      'bulletedList',
                      'numberedList',
                      'todoList',
                      '|',
                      'outdent',
                      'indent'
                    ],
                    shouldNotGroupWhenFull: true
                  }
                }}
              />
            </CKEditorContext>
            <div style={{ height: 20 }}></div>
            <Button color="primary" variant="contained" type="submit" fullWidth>
              Send Email
            </Button>
          </form>
        </DialogContent>
      )}
    </BootstrapDialog>
  );
}

export default ShopEmail;

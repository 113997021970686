import { Autocomplete, Box, Button, Container, Dialog, DialogContent, DialogTitle, IconButton, Link, TextField, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/services/InventoryService';



export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  setCustomerList:any;
  customerList: any;
  index:any
}


function CustomerEdit({
  open,
  onClose,
  customerId,
  setCustomerList,
  customerList,
  index
}) {

  const validationSchema = yup.object({
    customerName: yup.string().typeError('Not a String').required('Required'),
    email: yup.string().typeError('Not a String').required('Required'),
    phoneNumber: yup.number().typeError('Not a String').required('Required')
  });

  const formikupdate = useFormik({
    initialValues: {
      customerName: customerList[index]?.customerName || '',
      email: customerList[index]?.email || '',
      phoneNumber: customerList[index]?.phoneNumber || ''
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateCustomer(values);
      // formikupdate.resetForm();
    }
  });

  const updateCustomer = (values: any) => {
    onClose();
    // const id = customerId;
    const data = {
      customerName: values.customerName,
      email: values.email,
      phoneNumber: values.phoneNumber
    };
    InventoryService.updateCustomer(data, customerId).then((res) => {
      if (res.success) {
         const updatedCustomerList = [...customerList];
         updatedCustomerList[index] = {
           ...updatedCustomerList[index],
           customerName: values.customerName,
           email: values.email,
           phoneNumber: values.phoneNumber,
         };

         setCustomerList(updatedCustomerList);
        Swal.fire('Shop Updated', 'Successfully', 'success');
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00'
        });
      }
    });
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
  
      <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
        Edit Customer Details
      </Typography>
      <DialogContent dividers>
        {customerList ? (
          <form onSubmit={formikupdate.handleSubmit}>
            <Typography>Customer Name</Typography>
            <TextField
              required
              id="customerName"
              name="customerName"
              value={formikupdate.values.customerName}
              onChange={formikupdate.handleChange}
              fullWidth
            />
            <div style={{ height: 20 }}></div>
            <Typography>Customer Email</Typography>
            <TextField
              required
              id="email"
              name="email"
              value={formikupdate.values.email}
              onChange={formikupdate.handleChange}
              fullWidth
            />
            <div style={{ height: 20 }}></div>
            <Typography>Customer Phone Number</Typography>
            <TextField
              required
              id="phoneNumber"
              name="phoneNumber"
              value={formikupdate.values.phoneNumber}
              onChange={formikupdate.handleChange}
              fullWidth
            />
            <div style={{ height: 20 }}></div>

            <Button color="primary" variant="contained" type="submit" fullWidth>
              Update
            </Button>
          </form>
        ) : (
          <Typography variant="body1">Loading...</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default CustomerEdit;

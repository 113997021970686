import { Autocomplete, Box, Button, Container, Dialog, DialogContent, DialogTitle, IconButton, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/services/InventoryService';
import { AuthService } from 'src/services/AuthService';
import { ManagementServices } from 'src/services/ManagementServices';
import { CurrencyDetails } from 'src/models/Currency';
import { AppResponse } from 'src/models/Response';
import Papa from 'papaparse';
import UploadIcon from '@mui/icons-material/Upload';
import { TemplateInventoryDetails } from 'src/models/InventoryDetails';


export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
  get? : any;
}
function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}


function ImportShopInventoryTemplate({ open, onClose, shopId, allShops,setAllShops,index }) {
  const [shopDetails, setShop] = useState<any>([]);
  const [userDetails, setUser] = useState<any>([]);

  const [inventoryCategory, setInventoryCategory] = useState('')
  const [mainTableInventory, setMainTableInventory] = useState<TemplateInventoryDetails[]>([]);
  // console.log('mainTableInventory', mainTableInventory)
  const theme = useTheme();

  const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
      width: '50vw', // Set the desired width for the dialog box
      maxWidth: 'none', // Remove the max-width constraint
    },
  }));


  useEffect(() => {
    if (open) {
      getAllTemplates();
    }
  }, [open, shopId]);

  const getAllTemplates = () => {
    InventoryService.getinventory().then(
      (res) => {
        if (res.success) {
          setMainTableInventory(res.data);


        } else {

        }
      }
    );
  };


  const handleImport = async (inventoryTemplateId: any, shopId: string) => {
    const res = await InventoryService.importInventoryTemplate(shopId, inventoryTemplateId);
    if (res.success == true) {
        let inventoryApiCount = res.data?.length
        const totalTemplateInventoryCount = allShops[index]?.inventoryCount + inventoryApiCount
        const updatedTemplageProduct = [...allShops]; 
        updatedTemplageProduct[index] = { ...updatedTemplageProduct[index], inventoryCount: totalTemplateInventoryCount};
        setAllShops(updatedTemplageProduct);
      Swal.fire('Import Inventory', 'Successfully', 'success');
      //get();
      onClose();
    } else if (res.success == false) {
      Swal.fire(res.error, 'Not Completed', 'error');
      onClose();
    }
  };

const handleClose = () => {
  onClose();
};

  return (
    <StyledDialog open={open} onClose={onClose}>
     
      {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography variant="h4" fontWeight="bold">
          Import template inventory
        </Typography>
      </BootstrapDialogTitle> */}
      <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
      Import template inventory
        </Typography>
      <DialogContent dividers>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Template No</TableCell>
                <TableCell>Template Name</TableCell>
                <TableCell align="right">Import</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mainTableInventory?.map((data, index) => (
                <TableRow hover key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{data.templateName}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Import template inventory" arrow>
                      <IconButton
                        onClick={() => handleImport(data._id, shopId)}
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <UploadIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </StyledDialog>
  );
}

export default ImportShopInventoryTemplate;

import { Autocomplete, Box, Button, Container, Dialog, DialogContent, DialogTitle, IconButton, Link, TextField, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/services/InventoryService';
import { AppResponse } from 'src/models/Response';
import Papa from 'papaparse';


export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}


function ImportCSV({ open, onClose, shopId, get}) {
  const [shopDetails, setShop] = useState<any>([]);
  const [userDetails, setUser] = useState<any>([]);

  const [inventoryCategory, setInventoryCategory] = useState('')
  const [importCSV, setimportCSV] = useState(null)
  const [csvFile, setCsvFile] = useState(null);
 

  const currencyTab = userDetails.currency?.currencyName + " - "+ userDetails.currency?.countryName


  useEffect(() => {
    let isMounted = true;

    return () => {
      isMounted = false;
    };
  }, [shopId]);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);

    
  };

  const handleCategoryChange = (event) => {
    setInventoryCategory(event.target.value);
  };

  const handleImportClose = () => {
    onClose();
    setimportCSV(null);
  };

  
  const  handleUploadCsv = async (e:any) => {
    e.preventDefault();
    handleImportClose();
    if (!csvFile) {
      return;
    }

    const categoryInput = document.getElementById('category') as HTMLInputElement;
    const categoryValue = categoryInput.value;
    

    Papa.parse(csvFile, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete:  async (results) => {
        const inventories = results.data.map((row) => ({
          // shopId:shopId,
          shopId:shopId,
          inventoryCategory: categoryValue,
          sKUNumber: row['SKU Number'],
          productName: row['Product Name'],
          price: row['Price'],
          quantity: row['Quantity'],
          brandName: row['Brand Name'],
          
        }));
  
           
        

        const response: AppResponse<any> = await InventoryService.createMultipleInventoriesForShop(inventories, shopId) 
            
            if (response.success) {
              setCsvFile(null);
              setInventoryCategory('');
              Swal.fire('Inventories Created for shop', 'Successfully', 'success');
              get();
            } else {
              setCsvFile(null);
              Swal.fire({
                icon: 'error',
                title: response.error,
                confirmButtonColor: '#FD7F00'
              });
            }
          
          
          
      },
    });
  };
 
  
  return (
    <Dialog open={open}
    onClose={onClose} fullWidth 
    maxWidth="md">
        {/* <DialogTitle>
        <Typography variant="h4" fontWeight="bold">
        Import CSV
    </Typography>
          </DialogTitle> */}
          <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
          Import CSV
        </Typography>
        
        <DialogContent dividers>
          <form onSubmit={handleUploadCsv}>
          
          <TextField
                required
                id="category"
                label="category"
                value={inventoryCategory}
                onChange={handleCategoryChange}
                fullWidth
               
              />

            <div style={{ height: 20 }}></div>
            <input
              id="csv-file-input"
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <label htmlFor="csv-file-input">
              <Button variant="outlined" color="primary" component="span" fullWidth>
                {csvFile ? csvFile.name : 'Choose CSV File'}
              </Button>
            </label>
            <Box mt={3}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                // onClick={handleUploadCsv}
                fullWidth
              >
                Create inventory for this shop
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
  );
}

export default ImportCSV;

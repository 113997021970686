import React, { useEffect, useState } from 'react';
import { Modal, Box,IconButton, Typography, TextField, Button, DialogContent, Dialog, Select, MenuItem, SelectChangeEvent, DialogTitle } from '@mui/material';
import { InventoryDetails } from "src/models/Inventory";
import { InventoryService } from 'src/services/InventoryService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { InventoryCategories } from 'src/models/InventoryCategory';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthService } from 'src/services/AuthService';
import { ManagementServices } from 'src/services/ManagementServices';
import CloseIcon from '@mui/icons-material/Close';


// export interface ProductProps {
//     open: boolean;
//     onClose: () => void;
//   shopId: any;
//   setCustomerList:any,
//   customerList:any
    
// }
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function CreateCustomer ({
  open,
  onClose,
  shopId,
  setCustomerList,
 customerList}) {
  const [categories, setCategories] = useState<
    { _id: string; categoryName: string }[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [categoryModalOpen, setCategoryModalOpen] = useState<boolean>(false);
  const [shopDetails, setShop] = useState<any>([]);

  useEffect(() => {
    getShopByShopId(shopId);
  }, [shopId]);

  const getShopByShopId = async (shopId: string) => {
    try {
      const res = await AuthService.getShopByShopId(shopId);
      setShop(res.data);
    } catch (error) {
      console.log('Failed to fetch shop details:', error);
    }
  };

  const handleSubmit = (values: any, { resetForm }) => {
    const data: any = {
      shopId: shopId,
      customerName: values.customerName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      userId: shopDetails.createdBy,
      syncWithContact: 'false',
      hasCompleted: 'PENDING'
    };
    InventoryService.CreateCustomers(data).then((res) => {
      if (res.success) {
        Swal.fire('Customer Created', 'Successfully', 'success');
        const updatedcustomerList = [data, ...customerList];
        setCustomerList(updatedcustomerList);
        resetForm();
        onClose();
      } else {
        resetForm();
        onClose();
        Swal.fire({
          icon: 'error',
          title: 'Failed to Add Product',
          confirmButtonColor: '#FD7F00'
        });
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      shopId: '',
      customerName: '',
      email: '',
      phoneNumber: '',
      userId: '',
      syncWithContact: ''
    },
    validationSchema: Yup.object({
      customerName: Yup.string().required('Customer Name is required'),
      email: Yup.string().required('email is required'),
      phoneNumber: Yup.string().required('Phone Number is required')
    }),
    onSubmit: handleSubmit
  });

  const handleClose = () => {
    onClose();
  };
  return (
    <div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
      >
        {/* <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Typography variant="h4" fontWeight="bold">
              Create a new Customer
            </Typography>
          </BootstrapDialogTitle> */}
        <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
          Create a new Customer
        </Typography>
        <DialogContent dividers>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Field
                as={TextField}
                fullWidth
                label="customer Name"
                margin="normal"
                variant="outlined"
                name="customerName"
                error={
                  formik.touched.customerName &&
                  Boolean(formik.errors.customerName)
                }
                helperText={
                  formik.touched.customerName && formik.errors.customerName
                }
              />
              <Field
                as={TextField}
                fullWidth
                label="email"
                margin="normal"
                variant="outlined"
                name="email"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Field
                as={TextField}
                fullWidth
                label="phone Number"
                margin="normal"
                variant="outlined"
                name="phoneNumber"
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />

              <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Submit
              </Button>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateCustomer;

import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,CircularProgress
} from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { InventoryService } from 'src/services/InventoryService';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import EditInventoryTemplateProduct from 'src/components/EditInventoryTemplateProduct';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import AddProduct from './AddProduct';

type ViewSingleClientProfileProps = {
  callBackPage: () => void;
  selectedtemplateId: string;
};
const InventoryProductsTable: React.FC<ViewSingleClientProfileProps> = ({ callBackPage, selectedtemplateId }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedQuantity, setQuantity] = useState(null);
  const [selectedPrice, setPrice] = useState(null);
  const [selectedInventoryId, setInventoryId] = useState(null);
  const [templateProduct, setTemplateProduct] = useState<any>([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [ifSearchChanged, setIfSearchChanged] = useState(false);
  useEffect(() => {
    getAllTemplateProduct();
  }, [currentSearchTerm, page, rowsPerPage, selectedtemplateId]);


  const getAllTemplateProduct = () => {
    const data = {
      searchTag: currentSearchTerm
    };
    InventoryService.getDefaultInventoryProducts(data, (page + 1).toString(), rowsPerPage, selectedtemplateId).then(
      (res) => {
        if (res.success) {
          if(ifSearchChanged ){
            const templateProducts = res.data.inventories || [];
            setTemplateProduct(templateProducts); 
          }
          else{
            const templateProducts = res.data.inventories || [];
            setTemplateProduct(prevList => [...prevList, ...templateProducts]); 
          }
          setLoading(false)
          setTotalCount(res.data.totalCount);
        } else {
          toast.error("Failed to fetch inventory");
        }
        setIsLoading(false);
      }
    );
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setLoading(true)
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEditOpen = (index, product, quantity, price, inventoryId) => {
    setSelectedProduct(product);
    setQuantity(quantity);
    setPrice(price);
    setInventoryId(inventoryId);
    setOpenEdit(true);
    setEditIndex(index)
  };

  const handleSearch = () => {
    setCurrentSearchTerm(searchKeyword);
    setIfSearchChanged(true)
    setPage(0);
  };

  const handleSearchClear = () => {
    setIfSearchChanged(true)
    setSearchKeyword('');
    setCurrentSearchTerm('');
    setPage(0);
    getAllTemplateProduct();
  };

  const theme = useTheme();

  const back = () => {
    callBackPage()
  };


  const deleteConfirm = (templateProductId: any,index:number) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: 'Close'
    }).then((result) => {
      if (result.isConfirmed) {
        InventoryService.deleteTemplateProduct(templateProductId).then((res) => {
          Swal.fire(
            'Deleted!',
            'Template product has been deleted.',
            'success'
          ).then((result) => {
            if (result.isConfirmed) {
             // getAllTemplateProduct();
             const updatedTemplageProduct = [...templateProduct];
             updatedTemplageProduct.splice(index, 1);
             setTemplateProduct(updatedTemplageProduct);
            }
          });
        }).catch((error) => {
          Swal.fire(
            'Error!',
            'There was an error deleting your file.',
            'error'
          );
        });
      }
    });
  };


  const handleAddProductClose = () => {
    setOpenAddProduct(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => back()}
        sx={{
          borderColor: 'primary.main',
          color: 'primary.main', 
          '&:hover': {
            borderColor: 'primary.dark', 
            color: 'primary.dark'     
          },
          mb: 3
        }}
      >
        Back
      </Button>

      <Card>
        <CardHeader
          title={
            <Typography
              variant="h4"
              component="h2"
              sx={{
                fontSize: '2rem',
                fontWeight: 'bold',

              }}
            >
              Inventory Products Details
            </Typography>
          }
          action={
            <Box display="flex" alignItems="center" gap={1}>
              <AddProduct templateId={selectedtemplateId} get={getAllTemplateProduct}/>
              <TextField
                variant="outlined"
                placeholder="Search Product"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleSearch()} color="primary">
                        <SearchIcon />
                      </IconButton>
                      <IconButton onClick={handleSearchClear} color="error">
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          }
        />
        <Divider />
        {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <CircularProgress />
        </Box>
      ) :(
      <>
      <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Index</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>SKU Number</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Brand Name</TableCell>
                <TableCell>Category Name</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

            {templateProduct.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                  <Typography 
                      variant="h4" 
                      component="h2" 
                      sx={{ 
                        fontSize: '1rem',
                        fontWeight: 'bold',
                      }}
                    >
                    No data to display
                  </Typography>
                  </TableCell>
                </TableRow>
              ) : (

              templateProduct.map((inventory, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{index + 1 }</TableCell>
                    <TableCell>{inventory.productName}</TableCell>
                    <TableCell>{inventory.sKUNumber}</TableCell>
                    <TableCell>{inventory.quantity}</TableCell>
                    <TableCell>{inventory.price}</TableCell>
                    <TableCell>{inventory.brandName}</TableCell>
                    <TableCell>{inventory.categoryName}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="Preview" arrow>
                        <IconButton
                          onClick={() => handleEditOpen(index, inventory.productName, inventory.quantity,  inventory.price, inventory._id)}
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Preview" arrow>
                        <IconButton
                           onClick={() => deleteConfirm(inventory._id,index)}
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <DeleteTwoToneIcon fontSize="small" color="error"/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                  </TableRow>
                )))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}  display="flex" justifyContent="center">
       {
        totalCount > templateProduct.length && (
          <Button
          variant="contained"
          color="primary"
          onClick={(e) => handlePageChange(e, page + 1)}
          >
          {loading ? 'Loading...' : 'See More'}
        </Button>
        )
       }
          {/* <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[20, 30, 40, 50]}
          /> */}
        </Box>
      </>
      )}
        
      </Card>

      <EditInventoryTemplateProduct
        open={openEdit}
        onClose={handleEditClose}
        productName={selectedProduct}
        quantity={selectedQuantity}
        price={selectedPrice}
        shopId="template"
        inventoryId={selectedInventoryId}
        templateProduct={templateProduct}
        setTemplateProduct={setTemplateProduct}
        index={editIndex}
      />



    </>
  );
};

export default InventoryProductsTable;
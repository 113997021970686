import { Autocomplete, Box, Button, CircularProgress, Container, Dialog, DialogContent, DialogTitle, IconButton, Link, TextField, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { InventoryService } from 'src/services/InventoryService';
import { AuthService } from 'src/services/AuthService';
import { ManagementServices } from 'src/services/ManagementServices';
import { CurrencyDetails } from 'src/models/Currency';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 5 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function EditShop({ open, onClose, shopId, userId, allShops, setAllShops, index }) {
  const [shopDetails, setShop] = useState<any>([]);
  const [userDetails, setUser] = useState<any>([]);
  const [currency, setCurrency] = useState([] as CurrencyDetails[]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currencyTab, setCurrencyTab] = useState('');

  useEffect(() => {
    if (shopId && open) {
      fetchData();
    }
  }, [shopId, userId, open]);

  const fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([getShopByShopId(shopId), getUserByUserId(userId), getCurrencyList()]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getShopByShopId = async (shopId: string) => {
    try {
      const res = await AuthService.getShopByShopId(shopId);
      if (res?.data) {
        setShop(res.data.shopDetails.shopDetails);
      }
    } catch (error) {
      console.error('Failed to fetch shop details:', error);
    }
  };

  // Fetch user details
  const getUserByUserId = async (userId: string) => {
    try {
      const res = await AuthService.getUserByUserId(userId);
      if (res.data) {
        setUser(res.data);
      }
    } catch (error) {
      console.error('Failed to fetch user details:', error);
    }
  };

  // Fetch currency list
  const getCurrencyList = async () => {
    try {
      const res = await ManagementServices.getAllCurrencyList();
      if (res.data) {
        const filteredCurrencies = res.data.filter(
          (item) => item.currencyName === 'LKR' || item.currencyName === 'USD'
        );
        setCurrency(filteredCurrencies);
      }
    } catch (error) {
      console.error('Failed to fetch currency list:', error);
    }
  };

  useEffect(() => {
    if (userDetails?.currency) {
      const { currencyName, countryName } = userDetails.currency;
      const matchedCurrency = currency.find(
        (item) => item.currencyName === currencyName && item.countryName === countryName
      );
      setSelectedCurrency(matchedCurrency || null);
    }
  }, [userDetails, currency]);

  const validationSchema = yup.object({
    ShopName: yup.string().typeError('Not a String').required('Required'),
    OwnerName: yup.string().typeError('Not a String').required('Required'),
    ShopRegisterNumber: yup.string().typeError('Not a String').required('Required'),
    PhoneNumber: yup.string().typeError('Not a String').required('Required'),
    ShopAddress: yup.string().typeError('Not a String').required('Required'),
  });

  const formikupdate = useFormik({
    initialValues: {
      ShopName: shopDetails?.shopName || '',
      OwnerName: shopDetails?.ownerName || '',
      ShopRegisterNumber: shopDetails?.shopRegisterNumber || '',
      PhoneNumber: shopDetails?.phoneNumber || '',
      ShopAddress: shopDetails?.shopAddress || '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateShop(values);
      updateCurrency(selectedCurrency?._id);
      formikupdate.resetForm();
    },
  });

  const updateShop = (values) => {
    onClose();
    const id = shopId;
    const data = {
      shopName: values.ShopName,
      shopRegisterNumber: values.ShopRegisterNumber,
      ownerName: values.OwnerName,
      phoneNumber: values.PhoneNumber,
      shopAddress: values.ShopAddress,
    };
    InventoryService.updateShop(data, id).then((res) => {
      if (res.success) {
        const updatedShopName = data.shopName;
        const updatedPhoneNumber = data.phoneNumber;
        const updatedTemplageProduct = [...allShops];
        updatedTemplageProduct[index] = { ...updatedTemplageProduct[index], shopName: updatedShopName, phoneNumber: updatedPhoneNumber };
        setAllShops(updatedTemplageProduct);
        updateCurrency(selectedCurrency?._id);
        getShopByShopId(shopId);
        getUserByUserId(userId);
        getCurrencyList();
        Swal.fire('Shop Updated', 'Successfully', 'success');
      } else {
        Swal.fire({
          icon: 'error',
          title: res.error,
          confirmButtonColor: '#FD7F00',
        });
      }
    });
  };

  const updateCurrency = (currencyId) => {
    onClose();
    const id = userId;
    const data = {
      currency: currencyId,
    };
    InventoryService.updateUserC(data, id).then((res) => {
      if (res.success) {
        // Do nothing
      } else {
        // Swal.fire({
        //   icon: 'error',
        //   title: res.error,
        //   confirmButtonColor: '#FD7F00',
        // });
      }
    });
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <Typography variant="h4" fontWeight="bold" sx={{ p: 2 }}>
      Edit Shop
        </Typography>
      {loading ? (
         <Box display="flex" justifyContent="center" alignItems="center" height="300px">
         <CircularProgress />
       </Box>
      ) : (
        <DialogContent dividers>
          {shopDetails ? (
            <form onSubmit={formikupdate.handleSubmit}>
              <Typography>Shop Name</Typography>
              <TextField
                required
                id="ShopName"
                name="ShopName"
                value={formikupdate.values.ShopName}
                onChange={formikupdate.handleChange}
                fullWidth
              />
              <div style={{ height: 20 }}></div>
              <Typography>Owner Name</Typography>
              <TextField
                required
                id="OwnerName"
                name="OwnerName"
                value={formikupdate.values.OwnerName}
                onChange={formikupdate.handleChange}
                fullWidth
              />
              <div style={{ height: 20 }}></div>
              <Typography>Shop Register Number</Typography>
              <TextField
                required
                id="ShopRegisterNumber"
                name="ShopRegisterNumber"
                value={formikupdate.values.ShopRegisterNumber}
                onChange={formikupdate.handleChange}
                fullWidth
              />
              <div style={{ height: 20 }}></div>
              <Typography>Shop Phone Number</Typography>
              <TextField
                required
                id="PhoneNumber"
                name="PhoneNumber"
                value={formikupdate.values.PhoneNumber}
                onChange={formikupdate.handleChange}
                fullWidth
              />
              <div style={{ height: 20 }}></div>
              <Typography>Shop Address</Typography>
              <TextField
                required
                id="ShopAddress"
                name="ShopAddress"
                value={formikupdate.values.ShopAddress}
                onChange={formikupdate.handleChange}
                fullWidth
              />
              <div style={{ height: 20 }}></div>
              <Typography>Currency Type</Typography>
              <Autocomplete
                fullWidth
                id="currency"
                value={selectedCurrency || null}
                onChange={(event, value) => setSelectedCurrency(value)}
                options={currency}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option.currencyName || ''
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
              />
              <div style={{ height: 20 }}></div>
              <Button color="primary" variant="contained" type="submit" fullWidth>
                Update
              </Button>
            </form>
          ) : (
            <Typography variant="body1">Loading...</Typography>
          )}
        </DialogContent>
      )}
    </BootstrapDialog>
  );
}

export default EditShop;